import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { PrivateRoute } from "app/core/components/PrivateRoute";

export const RouteRenderer = ({ routes, errorRoute }) => (
  <Suspense fallback={<h1></h1>}>
    <Switch>
      {routes.map((route) =>
        route.private || route.roles ? (
          <PrivateRoute {...route} key={route.path} />
        ) : (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            render={(props) => <route.component {...props} {...route} />}
          />
        )
      )}
      {errorRoute && <Route component={() => <Redirect to="/" />} />}
    </Switch>
  </Suspense>
);
