import * as actionTypes from "app/store/actions/actionTypes";
import { updateObject } from "app/store/storeUtils";
import APP_CONST from "app/core/constant";
import _ from "underscore";

const initialState = {
  docType: [],
  tmfLevel: [],
  reviewerAction: [],
  commentListArr: [],
  docItemData: {},
  deleteDocumentInfo: {
    guid: "",
  },
  deletedDocumentGuid: "",
  lockDocumentChange: {},
  isDeleteLoading: false,
  resetViewDialog: true,
  noteArr: [],
  previousVersionId: "",
  isArtifactCall: false,
  noteModifiedDate: "",
  signatureReason: "",
  sharedRecipients: [],
  isShareLoading: false,
  subjects: [],
  docShareCount: {
    availableBindersCount: 0,
    completedBindersCount: 0,
    inCompleteBindersCount: 0,
    minDocumentVersionNumber: 0,
    availableBindersWithRecalledCount: 0
  },
  docShareUsers: {
    viedocmeusers: [],
    viedocusers: [],
    groupOptForViedocUser: [],
    groupOptForViedocMeUser: [],
    userRoleGroups: []
  },
  isBinderDetail: false,
  isSharingGridLoading: false,
  isBindersLoader: false,
  binderRecallErrState: "",
  createBinderError: "",
  bindersHistoryDetail: [],
  adminSharingSettings: [],
  downloadSignatureLoader: false,
  isValidDateValue: true,
  showHistorySpinner: false,
  fileHistoryListData:[],
  isDocumentModified:false,
  isDocContentLoader:false,
};

export const documentReducer = (
  state = initialState,
  action = { type: "" }
) => {
  switch (action.type) {
    case `${actionTypes.FETCH_DOCUMENT_MASTER}_SUCCESS`:
      const { documentType, documentLevels, reviewerActionMasterDto } =
        action.payload;
      const docType = documentType || [];
      const tmfLevel = documentLevels || APP_CONST.TMF_LEVELS;

      let reviewerAction = reviewerActionMasterDto || [];

      return updateObject(state, { docType, tmfLevel, reviewerAction });
    case `${actionTypes.FETCH_DOCUMENT_MASTER}_FAILED`:
      return updateObject(state, { docMaster: {} });
    case actionTypes.DOCUMENT_STATUS_CHANGE_SUCCESS:
      return updateObject(state, { docItemData: action.payload });
    case actionTypes.DOCUMENT_STATUS_CHANGE_FAILED:
      return updateObject(state, { docItemData: {} });
    case actionTypes.ADD_REVIEW_COMMENT:
      const updateCommentListArr = [...state.commentListArr];
      updateCommentListArr.push(action.payload);
      return updateObject(state, {
        commentListArr: updateCommentListArr,
      });
    case actionTypes.EMPTY_REVIEW_COMMENT:
      return updateObject(state, { commentListArr: [] });
    case `${actionTypes.FETCH_DOCUMENT_DELETE}`:
      return updateObject(state, {
        deleteDocumentInfo: { guid: "" },
        isDeleteLoading: true,
      });
    case `${actionTypes.FETCH_DOCUMENT_DELETE}_SUCCESS`:
      return updateObject(state, {
        deleteDocumentInfo: action.payload,
        deletedDocumentGuid: action.payload.documentguid,
        isDeleteLoading: false,
      });
    case `${actionTypes.FETCH_DOCUMENT_DELETE}_FAILED`:
      return updateObject(state, {
        deleteDocumentInfo: { guid: "" },
        isDeleteLoading: false,
      });

    case `${actionTypes.FETCH_DOCUMENT_LOCK}`:
      return updateObject(state, {});
    case `${actionTypes.FETCH_DOCUMENT_LOCK}_SUCCESS`:
      const updateDocumentPayload = {
        ...(action.payload.documentDetails || {}),
        ...action.payload,
      };
      const documentDetails = { documentDetails: { ...updateDocumentPayload } };
      const lockDocumentObj = {
        documentcontents: JSON.stringify(documentDetails),
      };
      return updateObject(state, {
        docItemData: lockDocumentObj,
        lockDocumentChange: updateDocumentPayload,
      });
    case `${actionTypes.FETCH_DOCUMENT_LOCK}_FAILED`:
      return updateObject(state, {});

    case `${actionTypes.GET_DOCUMENT_VERSION_LIST}_SUCCESS`:
      let versionList = action.payload;
      if (!_.isEmpty(versionList)) {
        versionList.map((version, index) => {
          versionList[index] = { value: version.documentversionnumber, label: version.documentversionnumber, ...version }
        })
      }
      return updateObject(state, { documentVersionList: versionList });

    case actionTypes.GET_SELECTED_DOCUMENT_DETAIL:
      return updateObject(state, { versionLoader: true });
    case `${actionTypes.GET_SELECTED_DOCUMENT_DETAIL}_SUCCESS`:
      return updateObject(state, { selectedDocDetail: action.payload, versionLoader: false });
    case `${actionTypes.GET_SELECTED_DOCUMENT_DETAIL}_FAILED`:
      return updateObject(state, { versionLoader: false });
    case `${actionTypes.RESET_VIEW_DIALOG}`:
      return updateObject(state, { resetViewDialog: action.payload });
    case actionTypes.ADD_DOC_NOTE:
      return updateObject(state, {});
    case `${actionTypes.ADD_DOC_NOTE}_SUCCESS`:
      let notesList = action.payload.documentNotesDetails;
      let noteChangedDate = action.payload.lastmodifiedon;
      return updateObject(state, {
        noteArr: notesList,
        noteModifiedDate: noteChangedDate,
        isArtifactCall: true
      });
    case `${actionTypes.GET_DOC_NOTE}_SUCCESS`:
      let notesDetail = action.payload.documentNotesDetails;
      let noteDate = action.payload.lastmodifiedon;
      return updateObject(state, {
        noteArr: notesDetail,
        noteModifiedDate: noteDate,
      });

    case `${actionTypes.RESOLVE_DOC_NOTE}_SUCCESS`:
      let noteResolvedDate = action.payload.lastmodifiedon;
      return updateObject(state, {
        noteModifiedDate: noteResolvedDate,
        isArtifactCall: true
      });

    case actionTypes.ERROR_WITH_DATE_VALUE:
      return updateObject(state, {
        isValidDateValue: action.payload
      });
    case actionTypes.CLEAR_DOC_NOTE:
      return updateObject(state, {
        noteArr: [],
        isArtifactCall: false
      });
    case actionTypes.PRE_VERSION_ID:
      return updateObject(state, {
        previousVersionId: action.payload,
      });
    case `${actionTypes.GET_SHARED_FILE_COUNT}`:
      return updateObject(state, {
        isShareLoading: true,
      });
    case `${actionTypes.GET_SHARED_FILE_COUNT}_SUCCESS`:
      let updatedSignatureList = action.payload.signaturereasons;
      let updatedRecipientList = action.payload.tmfsharingfilesreciepients.clinicalusers;
      let recipientListValues = [];
      let signatureListValues = [];
      updatedSignatureList.map((user) => {
        signatureListValues.push({ value: user, label: user })
      });
      updatedRecipientList.map((user) => {
        recipientListValues.push({ value: user.username, label: user.username, ...user })
      });
      return updateObject(state, {
        signatureReason: signatureListValues,
        sharedRecipients: recipientListValues,
        subjects: action.payload.tmfsharingfilesreciepients.subjects,
        isShareLoading: false,
      });
    case `${actionTypes.GET_SHARED_FILE_COUNT}_FAILED`:
      return updateObject(state, {
        isShareLoading: false,
      });
    case `${actionTypes.GET_SHARING_METRICS_BY_VERSION}`:
      return updateObject(state, {
        metricsLoader: true
      });
    case `${actionTypes.GET_SHARING_METRICS_BY_VERSION}_FAILED`:
      return updateObject(state, {
        metricsLoader: false
      });
    case `${actionTypes.GET_SHARING_METRICS_BY_VERSION}_SUCCESS`:
      let docShareCount = state.docShareCount;
      docShareCount = {
        ...docShareCount,
        availableBindersCount: action.payload.availableBindersCount,
        completedBindersCount: action.payload.completedBindersCount,
        inCompleteBindersCount: action.payload.inCompleteBindersCount,
        minDocumentVersionNumber: action.payload.minDocumentVersionNumber,
        availableBindersWithRecalledCount: action.payload.availableBindersWithRecalledCount
      };
      return updateObject(state, {
        docShareCount: docShareCount,
        metricsLoader: false
      });
    case `${actionTypes.CLEAR_BINDER_METRICS}`:
      return updateObject(state, {
        docShareCount: {
          availableBindersCount: 0,
          completedBindersCount: 0,
          inCompleteBindersCount: 0,
        }
      });
    case `${actionTypes.GET_DOC_SHARE_USER_LIST}_SUCCESS`:
      let docShareUsers = state.docShareUsers;
      let { viedocmeusers, viedocusers } = action.payload;
      let userRoleGroups = viedocusers.map((user) => {
        return user.rolegroupname;
      });
      userRoleGroups = userRoleGroups.filter((group, index) => userRoleGroups.indexOf(group) === index);
      let subjectSiteCodeGroups = viedocmeusers.map((subject) => {
        return subject.sitegroupname
      });
      subjectSiteCodeGroups = subjectSiteCodeGroups.filter((group, index) => subjectSiteCodeGroups.indexOf(group) === index);
      let mappedViedocMeUsers = [];
      !_.isEmpty(viedocmeusers) && viedocmeusers.map((user) => {
        user = {
          ...user,
          label: user.displayName,
          value: user.displayName
        }
        mappedViedocMeUsers.push(user);
      });
      let mappedViedocUsers = [];
      !_.isEmpty(viedocusers) && viedocusers.map((user) => {
        user = {
          ...user,
          label: user.displayName,
          value: user.displayName
        }
        mappedViedocUsers.push(user);
      });
      let groupOptForViedocUser = [];
      !_.isEmpty(userRoleGroups) && userRoleGroups.map((role) => {
        return groupOptForViedocUser.push({
          label: role,
          options: mappedViedocUsers.filter((user) => user.rolegroupname === role)
        })
      });
      let groupOptForViedocMeUser = [];
      !_.isEmpty(subjectSiteCodeGroups) && subjectSiteCodeGroups.map((subject) => {
        return groupOptForViedocMeUser.push({
          label: subject,
          options: mappedViedocMeUsers.filter((user) => user.sitegroupname === subject)
        })
      });
      docShareUsers = {
        viedocmeusers: mappedViedocMeUsers,
        viedocusers: mappedViedocUsers,
        groupOptForViedocUser: groupOptForViedocUser,
        groupOptForViedocMeUser: groupOptForViedocMeUser,
        userRoleGroups: userRoleGroups
      };
      return updateObject(state, {
        docShareUsers: docShareUsers,
      });
    case actionTypes.GET_EXISTING_SHARED_USER_LIST:
      return updateObject(state, {
        isAggridLoading: true,
      });
    case `${actionTypes.GET_EXISTING_SHARED_USER_LIST}_SUCCESS`:
      return updateObject(state, {
        existingUsersList: action.payload.binderList,
        isAggridLoading: false,
        isBinderDetail: false
      });
    case `${actionTypes.GET_EXISTING_SHARED_USER_LIST}_FAILED`:
      return updateObject(state, {
        isAggridLoading: false
      });
    case actionTypes.GET_BINDERS_DETAIL:
      return updateObject(state, {
        isBinderDetail: false,
        isBindersLoader: true
      });
    case `${actionTypes.GET_BINDERS_DETAIL}_FAILED`:
      return updateObject(state, {
        isBinderDetail: false,
        isBindersLoader: false

      });
    case `${actionTypes.GET_BINDERS_DETAIL}_SUCCESS`:
      return updateObject(state, {
        binderDetails: action.payload,
        isBinderDetail: true,
        isBindersLoader: false
      });
    case `${actionTypes.RECALL_BINDER}_SUCCESS`:
      return updateObject(state, {
        binderDetails: action.payload,
      });
    case `${actionTypes.RECALL_BINDER}_FAILED`:
      return updateObject(state, {
        binderRecallErrState: action.payload,
      });
    case `${actionTypes.CREATE_NEW_BINDER_FOR_SHARING}_FAILED`:
      return updateObject(state, {
        createBinderError: action.payload,
      });
    case `${actionTypes.IS_BINDERS_CALL_RUNNING}`:
      return updateObject(state, {
        isBindersCallRunning: action.payload,
      });

    case actionTypes.GET_DOCUMENT_CONTENT_DETAILS:
      return updateObject(state, {
        isDocContentLoader: true
      });
    case `${actionTypes.GET_DOCUMENT_CONTENT_DETAILS}_FAILED`:
      return updateObject(state, {
        isDocContentLoader: false

      });
    case `${actionTypes.GET_DOCUMENT_CONTENT_DETAILS}_SUCCESS`:
      return updateObject(state, {
        documentContentDetails: action.payload,
        isDocContentLoader: false
      });

    case actionTypes.GET_BINDERS_HISTORY:
      return updateObject(state, {
        bindersSpinner: true
      });
    case `${actionTypes.GET_BINDERS_HISTORY}_FAILED`:
      return updateObject(state, {
        bindersSpinner: false

      });
    case `${actionTypes.GET_BINDERS_HISTORY}_SUCCESS`:
      return updateObject(state, {
        bindersHistoryDetail: action.payload,
        bindersSpinner: false
      });

    case `${actionTypes.GET_ADMIN_SHARING_SETTING}_SUCCESS`:
      return updateObject(state, {
        adminSharingSettings: action.payload,
      });
    case `${actionTypes.DOWNLOAD_SIGNATURE_DETAILS}_FAILED`:
      return updateObject(state, {
        downloadSignatureLoader: false
      });
    case `${actionTypes.DOWNLOAD_SIGNATURE_DETAILS}_SUCCESS`:
      return updateObject(state, {
        downloadSignatureLoader: false
      });

    case `${actionTypes.DOWNLOAD_SIGNATURE_DETAILS}`:
      return updateObject(state, {
        downloadSignatureLoader: true,
      });
      case actionTypes.GET_HISTORY_DETAILS:
      return updateObject(state, {
        showHistorySpinner: true,
      });
    case `${actionTypes.GET_HISTORY_DETAILS}_SUCCESS`:
      return updateObject(state, {
        fileHistoryListData: action.payload,
        showHistorySpinner: false,
      });
    case `${actionTypes.GET_HISTORY_DETAILS}_FAILED`:
      return updateObject(state, {
        fileHistoryListData: [],
        showHistorySpinner: false,
      });
    case actionTypes.IS_DOCUMENT_MODIFIED:
      return updateObject(state, {
        isDocumentModified: action.payload,
      })  
    default:
      return state;
  }
};
