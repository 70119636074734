import * as actionTypes from "app/store/actions/actionTypes";

export const showPublishDialog = (payload, error) => ({
  type: actionTypes.SHOW_PUBLISH_DIALOG,
  payload,
  error
});

export const showViewDocDialog = (payload, error) => ({
  type: actionTypes.SHOW_VIEW_DOC_DIALOG,
  payload,
  error
});

export const updateErrorAlertDialog = (error) => ({
  type: actionTypes.UPDATE_ERROR_DIALOG,
  error
});
export const setViewDialogDetails = (payload) => ({
  type: `${actionTypes.SET_VIEW_DIALOG_DETAILS}`,
  payload
});