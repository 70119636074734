import * as actionTypes from "app/store/actions/actionTypes";

export const showLoader = () => ({
  type: actionTypes.SHOW_LOADER,
});
export const hideLoader = () => ({
  type: actionTypes.HIDE_LOADER,
});
export const showError = (error, downloadable = false, isSuccess = false) => ({
  type: actionTypes.SHOW_ERROR,
  payload: { error, downloadable, isSuccess },
});
export const hideError = () => ({
  type: actionTypes.HIDE_ERROR,
});
