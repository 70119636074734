import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { storageFactory } from "app/core/storageFactory";
import _ from "underscore";

const localStore = storageFactory(() => sessionStorage);
export const PrivateRoute = (route) => {
  let userHasRequiredRole = true;
  const isAuthed = JSON.parse(localStore.getItem("token_info"));
  const { exact, path, requiredRoles, ...rest } = route;
  const [callCount, setCallCount] = useState(1);

  if (requiredRoles) {
    // get current user role from storage and pass to below function include(role);
    userHasRequiredRole = requiredRoles.includes("admin");
  }

  setTimeout(() => {
    const appSettings = JSON.parse(localStore.getItem("appsettings"));

    if (_.isEmpty(isAuthed) && !_.isEmpty(appSettings) && callCount === 1) {
      window.parent.location.href = appSettings.viedocParentUrl;
      setCallCount(0);
    }
  }, 3000);

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) =>
        isAuthed && userHasRequiredRole ? (
          <route.component {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: userHasRequiredRole
                ? _.isEmpty(isAuthed)
                  ? "/status"
                  : "/"
                : "/",
            }}
          />
        )
      }
    />
  );
};
