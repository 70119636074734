import { put, takeEvery, take, call, fork, delay, takeLatest } from "redux-saga/effects";
import { eventChannel, END } from "redux-saga";
import * as actionTypes from "app/store/actions/actionTypes";
import * as actions from "app/store/actions/fileuploadActions";
import * as dialogActions from "app/store/actions/dialogAction";
import * as actionsLoader from "app/store/actions/loaderActions";
import * as documentActions from "app/store/actions/documentAction";
import AzureFileuploadHelper from "app/core/azureFileuploadHelper";
import { FileuploadService } from "app/secure/services/fileuploadService";
import APP_CONST from "app/core/constant";
import i18n from "i18nnext";
import { UtilsHelper } from "app/core/utilsHelper";
import * as zoneActions from "app/store/actions/zoneActions";
import { StorageHelper } from "app/core/storageHelper";
import _ from "underscore";

export function* watchUploadProgresssChannel(chan, guid) {
  let progress = 0;
  do {
    progress = yield take(chan);
    if (progress <= 100) {
      yield put(actions.fileUploadProgress({ progress, guid }));
    } else {
      yield put(actions.fileUploadProgress({}));
    }
  } while (progress < 100);
}

function createUploader(file, guid) {
  let emit;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => { };
  });
  emit(0);
  const uploadProgressCb = (ev) => {
    const percentage = Math.round((ev.loadedBytes * 100) / file.size);
    emit(percentage);
    if (percentage > 100) emit(END);
  };
  const dummyUploadProgressCb = (ev) => {
    let percentage = Math.round((ev.loadedBytes * 100) / file.size);
    for (let i = 0; i <= 10; i++) {
      percentage = i * 10;
      emit(percentage);
    }
    if (percentage > 100) emit(END);
  };
  let uploadPromise = null;
  if (file.size >= APP_CONST.FILE_SIZE.TENMB) {
    uploadPromise = AzureFileuploadHelper.uploadFiles(
      file,
      guid,
      uploadProgressCb
    );
  } else {
    uploadPromise = AzureFileuploadHelper.uploadFiles(
      file,
      guid,
      dummyUploadProgressCb
    );
  }
  return [uploadPromise, chan];
}

export function* uploadFile(data) {
  const { filePassPayload, guid, viewPerPageCount } = data.payload;
  const { docItem } = filePassPayload;
  try {
    const [uploadPromise, chan] = yield call(
      createUploader,
      filePassPayload.files,
      guid
    );
    // yield fork(watchUploadProgresssChannel, chan, guid);
    const res = yield call(() => uploadPromise);
    if (res.errorCode === undefined) {
      docItem.azureStorageResponse = JSON.stringify(res);
      const response = yield FileuploadService.doPassDocdetails(docItem);
      if (!response.data.isError) {
        const fileDetails = response.data.result;
        let docContent = fileDetails.documentcontents
          ? JSON.parse(fileDetails.documentcontents)
          : { documentDetails: {} };
        yield put(
          actions.scanningProcessStatus(guid, docItem.origiartifactid, {
            ...docContent.documentDetails,
            uploadingProgress: UtilsHelper.randomNumber([24, 32, 40]),
          }, viewPerPageCount)
        );
        yield put(actions.fileUploadSuccess(response.data.result));
      } else {
        // yield put(actionsLoader.showError(response.statusText));
        const errorMessage = UtilsHelper.constructErrorMsg(response.data);
        if (errorMessage === "File upload cancelled") {
          const { documentContents } = docItem;
          let docContent = documentContents
            ? JSON.parse(documentContents)
            : { documentDetails: {} };
          yield put(
            actions.fileUploadProgress({
              docObject: {
                ...docContent.documentDetails,
                uploadingProgress: 0,
              },
            })
          );
        } else {
          yield put(actions.fileUploadFailed({ ...docItem, errorMessage }));
        }
      }
    }
  } catch (e) {
    yield put(actionsLoader.showError(i18n.t("TMF.TOAST_FILEUPLOADFAILED")));
    yield put(actions.fileUploadFailed(docItem));
  }
}

export function* downloadFileUrl(data) {
  const { payload, isDialog } = data;
  let { requestPaylod, downloadInfo } = payload;
  let validFilename = UtilsHelper.fileInvalidCharsRemoval(downloadInfo.downloadFileName);
  if (validFilename.endsWith('.' + downloadInfo.type) === true) {
    requestPaylod.friendlyfilename = `${validFilename}`;
  } else {
    requestPaylod.friendlyfilename = `${validFilename}.${downloadInfo.type}`;
  }
  try {
    // yield put(actionsLoader.showLoader());
    const response = data.category ? yield FileuploadService.dodownloadDropzoneFileUrl(requestPaylod)
      : yield FileuploadService.dodownloadFileUrl(requestPaylod);
    if (!response.data.isError) {
      const { result } = response.data;
      yield put(actions.fileDownloadUrlSuccess(result, requestPaylod.fileguid));
      let fileurl = atob(result.sastoken);
      let downFileResponse = yield AzureFileuploadHelper.downloadAzureFile(
        fileurl,
        downloadInfo
      );

      if (downFileResponse === undefined) {
        yield put(actionsLoader.hideLoader());
      } else if (!downFileResponse.ok) {
        yield put(actionsLoader.hideLoader());
        if (isDialog) {
          yield put(
            dialogActions.updateErrorAlertDialog(
              `${i18n.t("TMF.TOAST_FILEDOWNLOADFAILED")} - ` +
              downFileResponse.statusText
            )
          );
        } else {
          yield put(
            actionsLoader.showError(
              `${i18n.t("TMF.TOAST_FILEDOWNLOADFAILED")} - ` +
              downFileResponse.statusText
            )
          );
        }
      }
    } else {
      const errorMessage = UtilsHelper.constructErrorMsg(response.data);
      if (isDialog) {
        yield put(
          dialogActions.updateErrorAlertDialog(
            `${i18n.t("TMF.TOAST_FILEDOWNLOADFAILED")} - ` + errorMessage
          )
        );
      } else {
        yield put(
          actionsLoader.showError(
            `${i18n.t("TMF.TOAST_FILEDOWNLOADFAILED")} - ` + errorMessage
          )
        );
      }
      yield put(actionsLoader.hideLoader());
      yield put(actions.fileDownloadUrlFailed(requestPaylod.documentid));
    }
  } catch (error) {
    yield put(actionsLoader.hideLoader());
    yield put(actionsLoader.showError(i18n.t("TMF.TOAST_FILEDOWNLOADFAILED")));
    if (isDialog) {
      yield put(
        dialogActions.updateErrorAlertDialog(
          i18n.t("TMF.TOAST_FILEDOWNLOADFAILED")
        )
      );
    } else {
      yield put(actionsLoader.showError(i18n.t("TMF.TOAST_FILEDOWNLOADFAILED")));
    }
  }
}

export function* scanningStatusSaga(data) {
  const { id, from, docObject, viewPerPageCount } = data.payload;
  try {
    const response = yield FileuploadService.doScanningStatus(id);
    if (!response.data.isError) {
      const { isscancompleted, scanresult, id, tmfdocumentid, documentstage, documentsystemversion, filesystemversion, documentversionguid } =
        response.data.result;
      const { uploadingProgress } = docObject;
      let newUploadingProgress =
        uploadingProgress <= 100
          ? uploadingProgress + UtilsHelper.randomNumber([6, 7, 8])
          : uploadingProgress;
      const newDocObject = {
        ...docObject,
        uploadingProgress: newUploadingProgress,
        documentsystemversion: documentsystemversion,
        filesystemversion: filesystemversion,
        documentversionguid: documentversionguid
      };
      let uploadProgressObj = {
        id,
        tmfdocumentid,
        artifactId: from,
        docObject: newDocObject,
      };
      const scanInfo = JSON.parse(scanresult);
      if (from !== "DocForm") {
        if (isscancompleted) {
          if (documentstage === "cancelled") {
            yield put(zoneActions.isUploadedNewVersionFile(false));
          } else if (scanInfo.Success) {
            const resultObj = {
              ...response.data.result,
              artifactId: from,
              docObject: { ...newDocObject, uploadingProgress: 100 },
            };
            yield put(
              actions.fileUploadProgress({
                ...uploadProgressObj,
                docObject: { ...newDocObject, uploadingProgress: 100 },
              })
            );
            yield put(zoneActions.isUploadedNewVersionFile(true));
            yield delay(5000);
            yield put(actions.scanningProcessStatusSuccess(resultObj));
            yield put(zoneActions.removeInvalidDocument(false));
            let lastdocumentupdatedon = sessionStorage.getItem("lastdocumentupdatedon");
            let req = {
              searchKey: "",
              site: "ALL",
              countryCode: "ALL",
              pageCount: 0,
              pageSize: viewPerPageCount || 20000,
              pageNumber: 1,
              artifactId: "",
              sectionid: "",
              category: "",
              milestoneGroup: "ALL",
              milestoneId: "",
              lastdocumentupdatedon: lastdocumentupdatedon
            };

            let recentSearchInfo = StorageHelper.getRecentSearchInfo();
            if (!_.isEmpty(recentSearchInfo)) {
              StorageHelper.setRecentSearchInfo(recentSearchInfo);
            } else {
              StorageHelper.setRecentSearchInfo(req);
            }
            yield put(zoneActions.fetchMetricsData());
            let isFileScan = StorageHelper.getIsAGGridApiRunning();
            if (isFileScan == "false") {
              StorageHelper.setIsAGGridApiRunning(true);
              yield put(zoneActions.fetchAllAggridData(req, false));
            }
          } else {
            const resultObj = { ...response.data.result, artifactId: from };
            yield put(
              actions.fileUploadProgress({
                ...uploadProgressObj,
                docObject: { ...newDocObject, uploadingProgress: 100 },
              })
            );
            yield delay(1000);
            yield put(actions.scanningProcessStatusFailed(resultObj));
            yield put(zoneActions.isUploadedNewVersionFile(false));
          }
        } else {
          if (documentstage !== "cancelled") {
            if (newUploadingProgress <= 96)
              yield put(actions.fileUploadProgress(uploadProgressObj));
            yield delay(3000);
            yield put(actions.scanningProcessStatus(id, from, newDocObject));
            yield put(zoneActions.isUploadedNewVersionFile(false));
          }
        }
      } else {
        if (isscancompleted) {
          if (documentstage === "cancelled") {
            yield put(actions.singleFileProgressing({ id: "" }));
          } else {
            yield put(
              actions.singleFileProgressing({ id, uploadingProgress: 100 })
            );
            yield delay(1000);
            yield put(
              actions.createNewDocVersionSuccess({ id, isscancompleted })
            );
            yield put(zoneActions.isUploadedNewVersionFile(true));
            if (!scanInfo.Success) {
              yield put(
                dialogActions.updateErrorAlertDialog(
                  i18n.t("TMF.TOAST_FILECONTAINVIRUSMESSAGE")
                )
              );
              yield put(actions.createNewDocVersionFailed());
              yield put(zoneActions.isUploadedNewVersionFile(false));
            }
          }
        } else {
          if (documentstage !== "cancelled") {
            yield put(actions.singleFileProgressing());
            yield delay(3000);
            yield put(actions.scanningProcessStatus(id, from, {}));
          }
        }
      }
    } else {
      yield put(actions.scanningProcessStatusFailed());
    }
  } catch (error) {
    if (from !== "DocForm") {
      yield put(actionsLoader.showError(i18n.t("TMF.TOAST_FILEUPLOADFAILED")));
    } else {
      yield put(
        dialogActions.updateErrorAlertDialog(i18n.t("TMF.TOAST_FILEUPLOADFAILED"))
      );
    }
  }
}

export function* createNewDocVersionSaga(data) {
  try {
    const response = yield FileuploadService.doCreateNewDocVersion(
      data.payload
    );
    if (!response.data.isError) {
      const { isscancompleted, id } = response.data.result;
      yield put(actions.createNewDocVersionSuccess({ id, isscancompleted }));
      yield put(actions.scanningProcessStatus(id, "DocForm", {}));
    } else {
      yield put(actions.createNewDocVersionFailed());
      const errorMessage = UtilsHelper.constructErrorMsg(response.data);
      if (errorMessage === "File upload cancelled") {
        yield put(dialogActions.updateErrorAlertDialog(errorMessage));
      }
    }
  } catch (error) {
    yield put(
      dialogActions.updateErrorAlertDialog(
        i18n.t("ADMIN.TOAST_CREATENEWVERSIONFAILED")
      )
    );
  }
}

export function* createNewDocVersionCancelSaga(data) {
  try {
    const response = yield FileuploadService.doCancelScanning(data.payload);
    if (!response.data.isError) {
      const { docObject = {} } = data;
      const { isscancompleted } = response.data.result;
      yield put(actions.createNewDocVersionSuccess(isscancompleted, "Cancel"));
      const { id, tmfdocumentid } = response.data.result;
      yield put(
        actions.fileUploadProgress({
          id,
          tmfdocumentid,
          artifactId: docObject && docObject.origiartifactid,
          docObject: { ...docObject, uploadingProgress: 0 },
        })
      );
    } else {
      yield put(actions.createNewDocVersionFailed());
    }
  } catch (error) {
    yield put(
      dialogActions.updateErrorAlertDialog(
        i18n.t("ADMIN.TOAST_CREATENEWVERSIONFAILED")
      )
    );
  }
}

export function* filePreviewSaga(data) {
  let requestPayload = {
    documentid: data.payload.requestPaylod.documentid,
    fileguid: data.payload.requestPaylod.fileguid,
    lastmodifiedon: data.payload.requestPaylod.lastmodifiedon,
    isDropZoneFile: data.isDropzone,
    isPreviewAudit: data.isPreviewAudit,
    friendlyfilename: data.payload.downloadInfo.downloadFileName,
    documentversionguid: data.versionId
  }
  let fileName = data.payload.downloadInfo.downloadFileName;
  let fileType = data.payload.downloadInfo.type;
  const response = yield FileuploadService.doPreviewFile(
    requestPayload
  );
  if (!response.data.isError) {
    const { sastoken } = response.data.result;
    let fileurl = atob(sastoken);
    yield put(actions.filePreviewUrlSuccess(fileurl, fileName, fileType, requestPayload));
  } else {
    yield put(
      dialogActions.updateErrorAlertDialog(i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD")));
    yield put(actions.filePreviewUrlFailed());
  }
}

export function* cancelNewversionAfterCreateSaga(data) {
  try {
    yield put(documentActions.doGetDocumentVersionList(data.docid));
    const recentSectionId = StorageHelper.getRecentSectionID();
    const recentSearchInfo = StorageHelper.getRecentSearchInfo();
    let req = recentSearchInfo;
    let isFileScan = StorageHelper.getDetailView();
    if (isFileScan === "true" || data.searchView) {
      StorageHelper.setIsAGGridApiRunning(true);
      yield put(documentActions.fetchDocMaster(true));
      yield put(zoneActions.fetchAllAggridData(req, false, false, true));
    }
    if (isFileScan !== "true" && !data.searchView) {
      if (!_.isEmpty(recentSectionId)) {
        yield put(zoneActions.getDocumentsBySection(recentSectionId, "", true));
      }
    }
    yield put(actions.cancelNewVersionAfterCreateSuccess(true));
  } catch (error) {
    yield put(actions.cancelNewVersionAfterCreateFailed());
  }
}


export function* fileUploadDropZoneSaga(data) {
  const { filePassPayload, guid } = data.payload;
  const { requestPayload, documentData } = filePassPayload;
}

export function* watchFileAsync() {
  yield takeEvery(actionTypes.FILE_UPLOAD, uploadFile);
  yield takeEvery(actionTypes.FILE_DOWNLOAD_URL, downloadFileUrl);
  yield takeEvery(actionTypes.SCANNING_PROCESS_STATUS, scanningStatusSaga);
  yield takeEvery(actionTypes.CREATE_NEW_DOC_VERSION, createNewDocVersionSaga);
  yield takeEvery(
    `${actionTypes.CREATE_NEW_DOC_VERSION}_CANCEL`,
    createNewDocVersionCancelSaga
  );
  yield takeEvery(actionTypes.DROP_ZONE_FILE_UPLOAD, fileUploadDropZoneSaga);
  yield takeEvery(actionTypes.FILE_PREVIEW_URL, filePreviewSaga);
  yield takeEvery(actionTypes.CANCEL_NEWVERSION_AFTER_CREATE, cancelNewversionAfterCreateSaga);
}
