class GetIPAndBrowserInfo {
    static getIPAddress = (url) => {
        return fetch(url).then(res => res.text());
    }

    static getOSName = () => {
        let userAgent = navigator.userAgent;
        let platform = navigator.platform;
        const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
        const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
        const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        let OSName = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            OSName = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            OSName = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            OSName = 'Windows';
        } else if (/Android/.test(userAgent)) {
            OSName = 'Android';
        } else if (!OSName && /Linux/.test(platform)) {
            OSName = 'Linux';
        }

        return OSName;
    }

    static getBrowserName = () => {
        let navUserAgent = navigator.userAgent;
        let browserName = navigator.appName;
        let fullVersion = '' + parseFloat(navigator.appVersion);
        let verOffset, ix;

        if ((verOffset = navUserAgent.indexOf("Edge/")) != -1) {
            browserName = "Edge";
            fullVersion = navUserAgent.substring(verOffset + 5);
        } else if ((verOffset = navUserAgent.indexOf("MSIE")) != -1) {
            browserName = "Microsoft Internet Explorer";
            fullVersion = navUserAgent.substring(verOffset + 5);
        } else if ((verOffset = navUserAgent.indexOf("Chrome")) != -1) {
            browserName = "Chrome";
            fullVersion = navUserAgent.substring(verOffset + 7);
        } else if ((verOffset = navUserAgent.indexOf("Safari")) != -1) {
            browserName = "Safari";
            fullVersion = navUserAgent.substring(verOffset + 7);
            if ((verOffset = navUserAgent.indexOf("Version")) != -1)
                fullVersion = navUserAgent.substring(verOffset + 8);
        } else if ((verOffset = navUserAgent.indexOf("Firefox")) != -1) {
            browserName = "Firefox";
            fullVersion = navUserAgent.substring(verOffset + 8);
        }

        // trim the fullVersion string at semicolon/space if present
        if ((ix = fullVersion.indexOf(";")) != -1)
            fullVersion = fullVersion.substring(0, ix);
        if ((ix = fullVersion.indexOf(" ")) != -1)
            fullVersion = fullVersion.substring(0, ix);

        return `${browserName} ${fullVersion}`;
    }
}

export default GetIPAndBrowserInfo;