import { BlobServiceClient } from "@azure/storage-blob";
import { StorageHelper } from "app/core/storageHelper";
import { TemplateService } from "app/secure/services/templateService";

class AzureFileuploadHelper {
  static uploadFiles = async (file, guid, onProgressCb) => {
    try {
      const sasTokenResult = await TemplateService.doFetchSAStoken();
      const { data } = sasTokenResult;
      const studyConfig = StorageHelper.getStudyConfig();
      if (!data.isError) {
        const { sastoken } = data.result;
        const decodedToken = atob(sastoken);
        const containerName = decodedToken.substring(
          decodedToken.lastIndexOf("/") + 1,
          decodedToken.lastIndexOf("?")
        );
        const splitDecodeURL = decodedToken.split(`/${containerName}?`);
        const sasString = `${splitDecodeURL[0]}/?${splitDecodeURL[1]}`;
        const containerURL = new BlobServiceClient(sasString);
        const containerClient = containerURL.getContainerClient(containerName);
        const updataGuid = `${studyConfig.studyid}_${guid}`;
        // const blobResp = await containerClient.getBlobClient(updataGuid).exists();
        // if (blobResp === false) {}
        // else { return "File Already exists";}
        const blockBlobClient = containerClient.getBlockBlobClient(updataGuid);
        const config = {
          metadata: {
            // filename: file.name,
          },
          onProgress: onProgressCb,
        };
        return blockBlobClient.uploadBrowserData(file, config);
      }

      // }
    } catch (error) {
      return "error";
    }
  };

  static downloadAzureFile = async (fileurl, fileInfo) => {
    return fetch(fileurl)
      .then((response) => {
        if (!response.ok) {
          // throw Error(response.statusText);
          return response;
        } else {
            let a = document.createElement("a");
            a.href = fileurl;
            a.click();
        }
      })
      .catch(function (error) {
        return error;
      });
  };
}
export default AzureFileuploadHelper;
