import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
// __webpack_public_path__ = "/";
import React from "react";
import { store } from "app/store/store";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { StorageHelper } from "app/core/storageHelper";
import App from "app/App";
import "./i18nnext";
import * as serviceWorker from "./serviceWorker";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "react-datepicker/dist/react-datepicker.css";
import "assets/theme/css/select2.css";
import "assets/theme/css/style.css";
import "assets/theme/css/datetimepicker.css";
import "./custom-styles.scss";

(async () => {
  await fetch("/appsettings.json")
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      document.title = res.etmftitle;
      StorageHelper.setAppSettings(res);
      return res;
    })
    .catch((e) => {
      return e;
    });

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
