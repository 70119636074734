import _ from "underscore";
import * as actionTypes from "app/store/actions/actionTypes";
import { updateObject } from "app/store/storeUtils";
import { StorageHelper } from "app/core/storageHelper";
import { UtilsHelper } from "app/core/utilsHelper";
const initialState = {
  zoneItems: [],
  artifactItems: [],
  selection: [],
  countrySiteList: {
    country: [],
    site: [],
  },
  selectedSiteID: "ALL",
  selectedCountryID: "ALL",
  selectedMilestoneID: "ALL",
  documentDataList: [],
  docLoading: false,
  roleList: [],
  studyUserInfo: {},
  userList: [],
  orgGuid: "",
  artifactPaginationInfo: [],
  metricsData: {},
  searchResult: {
    documentdetails: [],
  },
  searchView: false,
  searchLoader: false,
  viewPerPageCount: 20000,
  searchPageInfo: {
    activePage: 1,
    artifactid: "search",
    from: 0,
  },
  isReadOnlyAdmin: false,
  isReadOnlyUser: false,
  loadingArtifact: [],
  apiInitConfigObj: {},
  tmfList: [],
  auditList: [],
  listAudit: [],
  isDocShareViedocUser: false,
  isDocShareViedocMeUser: false,
  isEditLoading: false,
  aggridresultcount: 0,
  isAggridIconEnabled: false,
  archiveSiteLoad: true,
  isReadOnlySharingPanel: false,
  ismodifiedDocument: false,
  isExpandOpen: false
};

export const zoneReducer = (state = initialState, action = { type: "" }) => {
  switch (action.type) {
    case `${actionTypes.FETCH_ZONE_DATA}_SUCCESS`:
      let updatedZoneList = action.payload || [];
      // updatedZoneList = updatedZoneList.map((zoneObj) => {
      //   const returnObj = zoneObj;
      //   const sectionList = [...returnObj.sections];
      //   if (_.isEmpty(sectionList)) {
      //     const addNewSection = {
      //       artifacts: [],
      //       finalized: 0,
      //       id: 0,
      //       inReview: 0,
      //       name: "+ Add New Section",
      //       origid: "",
      //       uploaded: 0,
      //     };
      //     sectionList.push(addNewSection);
      //     returnObj.sections = sectionList;
      //   }
      //   return returnObj;
      // });
      return updateObject(state, { zoneItems: updatedZoneList });
    case actionTypes.SET_ARTIFACT_ITEMS:
      return updateObject(state, { artifactItems: action.payload });
    case actionTypes.RESET_SELECTION:
      return updateObject(state, { selection: [] });
    case actionTypes.UPDATE_SELECTION:
      let selectionArr = state.selection;
      selectionArr = [
        ..._.reject(selectionArr, (item) => {
          return item.type === action.payload.type;
        }),
        action.payload,
      ];
      return updateObject(state, { selection: selectionArr });
    case actionTypes.FETCH_ALL_CLIENT_APIS:
      return updateObject(state, {
        selectedSiteID: "ALL",
        selectedCountryID: "ALL",
        selectedMilestoneID: "ALL",
        isEditLoading: true
      });
    case actionTypes.ENABLE_AGGRID_ICON:
      return updateObject(state, {
        isAggridIconEnabled: action.payload
      });
    case `${actionTypes.GET_COUNTRY_SITE_LIST}_SUCCESS`:
      const { dropDownName, result } = action.payload;
      const updateCountryList = { ...state.countrySiteList };
      let updateResult = [];
      if (dropDownName === "site")
        updateResult = result.map((obj) => ({
          id: obj.guid,
          text: obj.siteName,
          description: obj.siteName,
          countryCode: obj.countryCode,
          siteCode: obj.siteCode,
        }));
      else
        updateResult = result.map((obj) => ({
          id: obj.countryCode,
          text: obj.country,
          description: obj.country,
          countrycode: obj.countryCode
        }));
      updateCountryList[`${dropDownName}`] = updateResult;
      return updateObject(state, {
        countrySiteList: updateCountryList,
        archiveSiteLoad: false
      });
    case actionTypes.SELECTED_FILTER_ID:
      const { id, name } = action;
      let newSiteID = state.selectedSiteID;
      let newCountryID = state.selectedCountryID;
      let newMilestoneID = state.selectedMilestoneID;
      if (name === "site") {
        if (id !== "ALL") {
          const siteList = state.countrySiteList.site;
          const findObj = _.find(siteList, { id: id });
          newSiteID = id;
          newCountryID = findObj ? findObj.countryCode : "ALL";
        } else newSiteID = "ALL";
      }
      if (name === "country") {
        newCountryID = id;
        newSiteID = "ALL";
      }
      if (name === "countryOnly") {
        newCountryID = id;
      }
      if (name === "mileStone") {
        newMilestoneID = id;
      }
      StorageHelper.setFilterInfo({
        selectedSiteID: newSiteID,
        selectedCountryID: newCountryID,
        selectedMilestoneID: newMilestoneID,
      });
      return updateObject(state, {
        selectedSiteID: newSiteID,
        selectedCountryID: newCountryID,
        selectedMilestoneID: newMilestoneID,
      });
    case `${actionTypes.GET_DOCUMENY_BY_ARTIFACT}`:
      let newList = [...state.loadingArtifact, action.payload];
      return updateObject(state, {
        loadingArtifact: [...newList],
        previewloading: true
      });
    case `${actionTypes.GET_DOCUMENY_BY_SECTION}`:
      const payloadFrom = action.from;
      return updateObject(state, {
        docLoading: !_.isEmpty(payloadFrom),
        artifactPaginationInfo: [],
        previewloading: true
      });
    case `${actionTypes.GET_DOCUMENY_BY_SECTION}_FAILED`:
      let newList2 = [...state.loadingArtifact];
      const resultFrom1 = action.from;
      if (!_.isEmpty(resultFrom1)) {
        newList2 = newList2.filter((list) => list !== resultFrom1);
      }
      return updateObject(state, {
        docLoading: false,
        loadingArtifact: [...newList2],
        previewloading: false
      });
    case `${actionTypes.GET_DOCUMENY_BY_SECTION}_SUCCESS`:
      const { documentDetails, adminartifactsharingsettings } = action.payload;
      let newList1 = [...state.loadingArtifact];
      const resultFrom = action.from;
      let groupDocument = _.groupBy(documentDetails, "origiartifactid");
      if (!_.isEmpty(resultFrom)) {
        newList1 = newList1.filter((list) => list !== resultFrom);
      }
      groupDocument = !_.isEmpty(documentDetails)
        ? groupDocument
        : { [resultFrom]: [] };
      return updateObject(state, {
        docLoading: false,
        documentDataList: { ...state.documentDataList, ...groupDocument },
        loadingArtifact: [...newList1],
        adminartifactsharingsettings: adminartifactsharingsettings,
        latestDocumentDetails: documentDetails,
        previewloading: false,
        isCustomized: action.isCustomized
      });
    case `${actionTypes.GET_ROLE_LIST}_SUCCESS`:
      let updateRoleList = action.payload;
      updateRoleList = updateRoleList.map((obj) => ({
        id: obj.roleId,
        text: obj.roleName,
      }));
      let tmfList = [];
      let auditList = [];
      let listAudit = [];
      updateRoleList.forEach(function (role) {
        if (role.text === "Archive sponsor TMF") {
          tmfList.push({ id: "Sponsor", text: "Sponsor" });
        } else if (role.text === "Archive investigator TMF") {
          tmfList.push({ id: "Investigator", text: "Investigator" });
        } else if (role.text === "Download audit trail") {
          auditList.push({ id: "Audit", text: "Audit" });
        }
      }
      )
      if (!_.isEmpty(auditList)) {
        listAudit.push({ name: "auditTrailReport", label: "Audit trail report", key: "audit" });
      }
      if (!_.isEmpty(tmfList)) {
        listAudit.push({ name: "emsRepository", label: "eTMF - EMS repository", key: "archive" });
      }
      const readOnlyAdminList = state.apiInitConfigObj["readonlyadmin"];
      const readOnlyUserList = state.apiInitConfigObj["readonlyuser"];
      const readOnlyAdmin = _.findIndex(updateRoleList, {
        id: !_.isEmpty(readOnlyAdminList) ? readOnlyAdminList[0] : "",
      });
      const checkIsReadOnlyUser = () => {
        const readOnlyUser = _.findIndex(updateRoleList, {
          id: !_.isEmpty(readOnlyUserList) ? readOnlyUserList[0] : "",
        });
        const etmfManager = _.findIndex(updateRoleList, { id: "ETMFMANAGER" });

        let returnValue = false;
        if (readOnlyUser !== -1) {
          returnValue =
            updateRoleList.length === 1 ||
            (updateRoleList.length === 2 &&
              (etmfManager !== -1 || readOnlyAdmin !== -1)) ||
            (updateRoleList.length === 3 &&
              etmfManager !== -1 &&
              readOnlyAdmin !== -1);
        }
        return returnValue;
      };
      const checkIsReadOnlySharingPanel = () => {
        const readOnlyUser = _.findIndex(updateRoleList, {
          id: !_.isEmpty(readOnlyUserList) ? readOnlyUserList[0] : "",
        });
        return readOnlyUser !== -1 ? true : false;
      }
      let isViedocUser = updateRoleList.length !== 0 && !_.isEmpty(updateRoleList.filter((role) => role.id === "MANAGE_DOCSHARE_VIEDOCUSERS")) ? true : false;
      let isViedocMeUser = updateRoleList.length !== 0 && !_.isEmpty(updateRoleList.filter((role) => role.id === "MANAGE_DOCSHARE_VIEDOCMEUSERS")) ? true : false;
      return updateObject(state, {
        tmfList: tmfList,
        auditList: auditList,
        listAudit: listAudit,
        roleList: updateRoleList,
        isReadOnlyAdmin: readOnlyAdmin !== -1,
        isReadOnlyUser: checkIsReadOnlyUser(),
        isDocShareViedocUser: isViedocUser,
        isDocShareViedocMeUser: isViedocMeUser,
        isReadOnlySharingPanel: checkIsReadOnlySharingPanel()
      });
    case `${actionTypes.GET_APP_INIT_CONFIG}_SUCCESS`:
      return updateObject(state, { apiInitConfigObj: action.payload });
    case `${actionTypes.GET_STUDY_INFO}_SUCCESS`:
      const studyInfo = {
        ...state.studyUserInfo,
        ...action.payload,
      };
      return updateObject(state, { studyUserInfo: studyInfo });
    case `${actionTypes.GET_USER_INFO}_SUCCESS`:
      const userInfo = {
        ...state.studyUserInfo,
        ...action.payload,
      };
      return updateObject(state, { studyUserInfo: userInfo });
    case `${actionTypes.GET_USER_LIST}_SUCCESS`:
      let updateUserList = action.payload;
      const systemAsUser = {
        displayName: "System(0)",
        guid: "00000000-0000-0000-0000-000000000001",
      };
      updateUserList.push(systemAsUser);
      return updateObject(state, { userList: updateUserList, isEditLoading: false });
    case `${actionTypes.GET_ORG_ID}_SUCCESS`:
      return updateObject(state, { orgGuid: action.payload });
    case actionTypes.FETCH_SEARCH_RESULT:
      return updateObject(state, {
        searchView: true,
        searchLoader: true,
      });
    case actionTypes.MAKE_SEARCH_VIEW:
      return updateObject(state, {
        searchView: true,
        searchLoader: true,
      });
    case `${actionTypes.FETCH_SEARCH_RESULT}_FAILED`:
      return updateObject(state, { searchView: true, searchLoader: false });
    case `${actionTypes.FETCH_SEARCH_RESULT}_SUCCESS`:
      const searchList = action.payload && action.payload;
      if (state.searchView || action.isSearchView) {
        return updateObject(state, {
          searchResult: searchList,
          searchView: true,
          searchLoader: false,
        });
      } else {
        return updateObject(state, {
          searchResult: [],
          searchView: false,
          searchLoader: false,
        });
      }
    case `${actionTypes.SAVE_ALL_AG_DATA}`:
      let agdata = !action.payload.uselocalstorage ? action.payload : state.allAggridData;
      sessionStorage.setItem("lastdocumentupdatedon", agdata?.lastdocumentupdatedon);
      return updateObject(state, { allAggridData: agdata, searchLoader: false, searchResult: agdata });
    case `${actionTypes.FETCH_ALL_AGGRID_DATA}_SUCCESS`:
      let data = !action.payload.uselocalstorage ? action.payload : state.allAggridData;
      return updateObject(state, { searchView: action.searchView, allAggridData: data, searchResult: agdata });
    case actionTypes.GET_AGGRID_RESULT_COUNT:
      return updateObject(state, { aggridresultcount: action.payload });
    case actionTypes.GO_TO_BROWSER_VIEW:
      return updateObject(state, { searchView: false, searchResult: [] });
    case `${actionTypes.SET_ARTIFACT_PAGINATION_INFO}`:
      const activePageInfo = action.payload;
      let updatePaginationInfo = [...state.artifactPaginationInfo];
      const findIndex = _.findIndex(updatePaginationInfo, {
        artifactid: activePageInfo.artifactid,
      });
      if (findIndex !== -1)
        updatePaginationInfo[findIndex] = { ...activePageInfo };
      else updatePaginationInfo.push(activePageInfo);
      return updateObject(state, {
        artifactPaginationInfo: updatePaginationInfo,
      });
    case actionTypes.REMOVE_INVALID_DOCUMENT:
      return updateObject(state, { isRemoveDoc: action.payload });
    case `${actionTypes.FETCH_METRICS_DATA}_SUCCESS`:
      return updateObject(state, { metricsData: action.payload });
    case `${actionTypes.FETCH_METRICS_DATA}_FAILED`:
      return updateObject(state, { metricsData: {} });
    case `${actionTypes.SET_SEARCH_VIEW_PER_PAGE}`:
      return updateObject(state, { viewPerPageCount: action.payload });
    case actionTypes.SET_SEARCH_PAGE_INFO:
      return updateObject(state, { searchPageInfo: action.payload });
    case actionTypes.RESET_DOCUMENT_DATA_LIST:
      let updateDocumentList = action.payload || [];
      const updateDocumentObj = {};
      updateDocumentList.map((obj) => {
        updateDocumentObj[obj.origid] = obj.docItemArr;
      });
      return updateObject(state, {
        documentDataList: { ...state.documentDataList, ...updateDocumentObj },
      });
    case actionTypes.REMOVE_SEARCH_DELETE_DOC:
      let removeDoc = state.searchResult.documentdetails.filter(doc => (_.isEmpty(doc.category) || doc.tmfdropzoneid && (doc.tmfdropzoneid !== action.payload.id)));
      return updateObject(state, {
        searchResult: {
          ...state.searchResult,
          documentdetails: removeDoc,
          totalRecords: state.searchResult.totalRecords - 1
        }
      });
    case actionTypes.UPLOADED_NEW_VERSION_FILE:
      return updateObject(state, { isUploadedNewVersionFile: action.payload });
    case actionTypes.TO_PASS_MODIFIED_FILE_DATA:
      return updateObject(state, { newlyModifiedDocumentData: action.payload, ismodifiedDocument: action.ismodifiedDocument });
    case actionTypes.IS_EXPAND_WINDOW:
      return updateObject(state, { isExpandOpen: action.payload });


    default:
      return state;
  }
};
