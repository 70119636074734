import * as actionTypes from "app/store/actions/actionTypes";
import { updateObject } from "app/store/storeUtils";
import { UtilsHelper } from "app/core/utilsHelper";
import _ from "underscore";

const initialState = {
  fileDetails: {},
  failedFileDetails: {},
  uploadProgress: { progress: 0 },
  azureConfig: {},
  fileDownloadDetails: {},
  scanSuccessInfo: {},
  scanFailedInfo: {},
  isScanning: false,
  saveChanges: false,
  isScanFailed: false,
  singleFile: { uploadingProgress: 0 },
  isAllowToCancel: false,
  downloadingFiles: [],
  screeningResponse: [],
  isCancelNewVersion: false
};

export const fileuploadReducer = (
  state = initialState,
  action = { type: "" }
) => {
  switch (action.type) {
    case actionTypes.FILE_UPLOAD_SUCCESS:
      return updateObject(state, { fileDetails: action.payload });
    case actionTypes.FILE_UPLOAD_FAILED:
      return updateObject(state, {
        fileDetails: {},
        failedFileDetails: action.payload,
      });
    case actionTypes.FILE_UPLOAD_PROGRESS:
      return updateObject(state, { uploadProgress: { ...action.payload } });
    case actionTypes.FILE_DOWNLOAD_URL:
      let downloadingFiles = state.downloadingFiles;
      downloadingFiles.push(action.payload.requestPaylod.fileguid);
      return updateObject(state, { downloadingFiles: downloadingFiles, downLoader: true });
    case actionTypes.SHOW_DOWNLOAD_LOADER:
      return updateObject(state, { downLoader: action.payload });
    case actionTypes.FILE_DOWNLOAD_URL_SUCCESS:
      const removeFiles = state.downloadingFiles.filter(id => id != action.documentid);
      return updateObject(state, { fileDownloadDetails: action.payload, downloadingFiles: removeFiles, downLoader: false });
    case actionTypes.FILE_DOWNLOAD_URL_FAILED:
      //const removeFailedFiles = state.downloadingFiles.filter(id => id !== action.payload)
      return updateObject(state, { fileDownloadDetails: {}, downloadingFiles: [], downLoader: false });
    case `${actionTypes.SCANNING_PROCESS_STATUS}`:
      return updateObject(state, {
        isDocumentUploading: true
      });
    case `${actionTypes.SCANNING_PROCESS_STATUS}_SUCCESS`:
      let screenResponse = state.screeningResponse;
      screenResponse.push(action.payload)
      return updateObject(state, {
        scanSuccessInfo: { ...action.payload, isAllowToCancel: false },
        screeningResponse: screenResponse,
        isDocumentUploading: false
      });
    case `${actionTypes.SCANNING_PROCESS_STATUS}_FAILED`:
      return updateObject(state, {
        scanFailedInfo: { ...action.payload, isAllowToCancel: false },
        isDocumentUploading: false
      });
    case `${actionTypes.CREATE_NEW_DOC_VERSION}`:
      return updateObject(state, { isScanning: true, saveChanges: false });
    case `${actionTypes.CREATE_NEW_DOC_VERSION}_SUCCESS`:
      if (action.from !== "Cancel") {
        return updateObject(state, {
          isScanning: !action.payload.isscancompleted,
          saveChanges: true,
          isScanFailed: false,
          isAllowToCancel: true,
        });
      } else
        return updateObject(state, {
          isScanFailed: false,
          saveChanges: false,
        });
    case `${actionTypes.CREATE_NEW_DOC_VERSION}_FAILED`:
      return updateObject(state, {
        isScanFailed: true,
        isScanning: false,
        saveChanges: false,
      });

    case actionTypes.FILE_PREVIEW_URL:
      return updateObject(state, {
        previewFileURL: "",
        isPreviewLoading: true
      });
    case `${actionTypes.FILE_PREVIEW_URL}_SUCCESS`:
      return updateObject(state, {
        previewFileURL: action.payload,
        fileName: action.fileName,
        fileType: action.fileType,
        isPreviewLoading: false,
        requestPayload: action.requestPayload
      });

    case `${actionTypes.FILE_PREVIEW_URL}_FAILED`:
      return updateObject(state, {
        previewFileURL: "",
        isPreviewLoading: false
      });

    case actionTypes.FILE_PREVIEW_OPEN:
      return updateObject(state, {
        isPreviewOpenFromDrozone: true,
        previewFileURL: ""
      });

    case actionTypes.SINGLE_FILE_PROGRESSING:
      let newPayload = { ...action.payload };
      if (_.isEmpty(newPayload)) {
        const { uploadingProgress, id } = state.singleFile;
        let newUploadingProgress =
          uploadingProgress <= 92
            ? uploadingProgress + UtilsHelper.randomNumber([6, 7, 8])
            : uploadingProgress;
        newPayload = {
          id,
          uploadingProgress: newUploadingProgress,
        };
      }
      return updateObject(state, {
        isScanning: !_.isEmpty(newPayload.id),
        singleFile: newPayload,
      });
    case `${actionTypes.CANCEL_NEWVERSION_AFTER_CREATE}_SUCCESS`:
      return updateObject(state, {
        isCancelNewVersion: action.payload
      });
    default:
      return state;
  }
};
