import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { routes } from "app/App.route";
import { RouteRenderer } from "app/core/components/RouteRenderer";
import { storageFactory } from "app/core/storageFactory";
import { StorageHelper } from "app/core/storageHelper";
import i18next from "i18next";
import _ from "underscore";
import { DURATION } from "./core/constant";
import { ZoneService } from "./secure/services/zoneService";

const App = () => {
    const localStore = storageFactory(() => sessionStorage);
    const { studyUserInfo } = useSelector((store) => store.zone);

    let remainingTime;
    let tokenExpiryDate;

    const tokenInfoHandler = () => {
        const tokenInformation = (localStore.getItem("token_info"));
        const tokenInformationJSON = JSON.parse(tokenInformation);
        tokenExpiryDate = new Date(tokenInformationJSON.tokenexpirydate);
        const currentDate = new Date();
        remainingTime = (((tokenExpiryDate - currentDate) / 1000) / 60);
        localStore.setItem("mintoexpiry", remainingTime);
        if (remainingTime < 5) {
            remainingTime = 1;
        }
    };

    useEffect(() => {

        setInterval(callRefreshCheck, DURATION);
        window.addEventListener("message", getEtmfToken, false);

        return () => {
            clearInterval(callRefreshCheck);
        }
    }, []);
    const [callCount, setCallCount] = useState(1);
    const [selectedLang, setSelectedLang] = useState('en');

    const sessionTimeoutCheck = async () => {
        const refreshTokenResponse = await ZoneService.getRefreshToken();
        if (refreshTokenResponse.status && refreshTokenResponse.status === 200) {
            const refreshTokenInfo = refreshTokenResponse.data.result;

            const token_info = {
                tokenInfo: refreshTokenInfo.token,
                tokenexpirydate: refreshTokenInfo.tokenexpirydate,
                usertype: "user"
            };

            const JSONtoken_info = JSON.stringify(token_info);

            localStore.setItem("jwtToken", refreshTokenInfo.token);
            localStore.setItem("token_info", JSONtoken_info);
            tokenInfoHandler();
        }
    }

    const callRefreshCheck = () => {
        const tokenInfo = (localStore.getItem("token_info"));
        const tokenInfoJSON = JSON.parse(tokenInfo);
        tokenExpiryDate = new Date(tokenInfoJSON.tokenexpirydate);
        const currentTime = new Date();
        remainingTime = ((tokenExpiryDate - currentTime) / 1000) / 60;
        localStore.setItem("mintoexpiry", remainingTime);
        if (remainingTime && remainingTime < 5) {
            sessionTimeoutCheck();
        }
    }

    const getEtmfToken = (event) => {
        let baseUrl = window.location.origin;
        let postRequestData;
        try {
            postRequestData = JSON.parse(event.data);
        } catch (ex) {
            // To prevent throwing parsing errors incase browser plug-ins trigger post message to iframe.
        }
        // Validate post message
        if (postRequestData && postRequestData.tokenInfo) {
            let token = postRequestData.tokenInfo;
            let url = baseUrl + (token ? "/" : "/error");
            localStore.clear();
            localStore.setItem("token_info", JSON.stringify(postRequestData));
            localStore.setItem("jwtToken", postRequestData.tokenInfo);
            // To ignore messages from other browser extensions, sending typed data
            let messageData = {
                redirectUrl: url,
            };
            window.parent.postMessage(JSON.stringify(messageData), "*");
        } else {
            const isAuthed = JSON.parse(localStore.getItem("token_info"));
            const appSettings = JSON.parse(localStore.getItem("appsettings"));

            if (_.isEmpty(isAuthed) && !_.isEmpty(appSettings)) {
                window.parent.location.href = appSettings.viedocParentUrl;
            }
        }
        tokenInfoHandler();
    };

    if (!_.isEmpty(studyUserInfo && studyUserInfo.language) && callCount === 1) {
        const lang = ((studyUserInfo.language === "en") ? "en-us" : studyUserInfo.language) || "en-us";
        setSelectedLang(lang);
        i18next.changeLanguage(lang);
        setCallCount(0);
    }


    return (
        <Router>
            <RouteRenderer routes={routes} errorRoute={true} />
        </Router>
    );
};
export default App;
