import { storageFactory } from "./storageFactory";

const localStore = storageFactory(() => sessionStorage);

export class StorageHelper {
  static TOKEN_INFO = "token_info";
  static JWT_TOKEN = "jwtToken";
  static STUDY_CONFIG = "studyconfig";
  static APP_SETTINGS = "appsettings";
  static FILTER_INFO = "filter_info";
  static RECENT_SEARCH_INFO = "recent_search_info";
  static RECENT_SECTION = "recent_section";
  static LATEST_SECTION = "latest_section";
  static IP_AND_BROWSER_INFO = "ip_and_browser_info";
  static ALL_AGGRID_DATA = "ALL_AGGRID_DATA";
  static AGGRID_SEARCH_INFO = "AGGRID_SEARCH_INFO";
  static IS_DETAIL_VIEW = "IS_DETAIL_VIEW";
  static IS_AGGRID_API_RUNNING = "IS_AGGRID_API_RUNNING";
  static FILTERED_DATA = "filtered_data";
  static CURRENT_DOCUMENT = "CURRENT_DOCUMENT";
  static ERROR_DOCUMENT = "ERROR_DOCUMENT";

  static clear() {
    localStore.clear();
  }

  static setTokenInfo(token) {
    localStore.setItem(this.TOKEN_INFO, JSON.stringify(token));
  }

  static getTokenInfo() {
    return JSON.parse(localStore.getItem(this.TOKEN_INFO));
  }

  static getJWTToken() {
    return localStore.getItem(this.JWT_TOKEN);
  }

  static setStudyConfig(val) {
    localStore.setItem(this.STUDY_CONFIG, JSON.stringify(val));
  }

  static getStudyConfig() {
    return JSON.parse(localStore.getItem(this.STUDY_CONFIG));
  }

  static setAppSettings(val) {
    localStore.setItem(this.APP_SETTINGS, JSON.stringify(val));
  }

  static getAppSettings() {
    return JSON.parse(localStore.getItem(this.APP_SETTINGS));
  }

  static setFilterInfo(val) {
    localStore.setItem(this.FILTER_INFO, JSON.stringify(val));
  }

  static getFilterInfo() {
    return JSON.parse(localStore.getItem(this.FILTER_INFO));
  }

  static setRecentSearchInfo(val) {
    if (val.site === "ALL")
      localStore.setItem(this.RECENT_SEARCH_INFO, JSON.stringify(val));
  }

  static getRecentSearchInfo() {
    return JSON.parse(localStore.getItem(this.RECENT_SEARCH_INFO));
  }

  static clearRecentSearchInfo() {
    return localStore.removeItem(this.RECENT_SEARCH_INFO);
  }

  static setRecentSectionID(val) {
    localStore.setItem(this.RECENT_SECTION, val);
  }

  static getRecentSectionID() {
    return localStore.getItem(this.RECENT_SECTION);
  }

  static clearRecentSectionID() {
    return localStore.removeItem(this.RECENT_SECTION);
  }

  static setIPAndBrowserDetails(val) {
    localStore.setItem(this.IP_AND_BROWSER_INFO, JSON.stringify(val));
  }

  static getIPAndBrowserDetails() {
    return JSON.parse(localStore.getItem(this.IP_AND_BROWSER_INFO));
  }
  static setLatestSectionID(val) {
    localStore.setItem(this.LATEST_SECTION, val);
  }

  static getLatestSectionID() {
    return localStore.getItem(this.LATEST_SECTION);
  }

  static clearLatestSectionID() {
    return localStore.removeItem(this.LATEST_SECTION);
  }
  static setAllAgGridData(val) {
    localStore.setItem(this.ALL_AGGRID_DATA, JSON.stringify(val));
  }

  static getAllAgGridData() {
    return localStore.getItem(this.ALL_AGGRID_DATA);
  }

  static clearAllAgGridData() {
    return localStore.removeItem(this.ALL_AGGRID_DATA);
  }

  static setRecentAggridSearchInfo(val) {
    localStore.setItem(this.AGGRID_SEARCH_INFO, JSON.stringify(val));
  }

  static getRecentAggridSearchInfo() {
    return localStore.getItem(this.AGGRID_SEARCH_INFO);
  }

  static clearRecentAggridSearchInfo() {
    return localStore.removeItem(this.AGGRID_SEARCH_INFO);
  }
  static setDetailView(val) {
    localStore.setItem(this.IS_DETAIL_VIEW, val);
  }
  static getDetailView() {
    return localStore.getItem(this.IS_DETAIL_VIEW);
  }

  static clearRecentAggridSearchInfo() {
    return localStore.removeItem(this.IS_DETAIL_VIEW);
  }
  static setIsAGGridApiRunning(val) {
    localStore.setItem(this.IS_AGGRID_API_RUNNING, val);
  }
  static getIsAGGridApiRunning() {
    return localStore.getItem(this.IS_AGGRID_API_RUNNING);
  }

  static clearsAGGridApiRunning() {
    return localStore.removeItem(this.IS_AGGRID_API_RUNNING);
  }

  static setFilteredData(val) {
    localStore.setItem(this.FILTERED_DATA, JSON.stringify(val));
  }
  static getFilteredData() {
    return localStore.getItem(this.FILTERED_DATA);
  }

  static clearFilteredData() {
    return localStore.removeItem(this.FILTERED_DATA);
  }

  static setCurrentDocument(val) {
    localStore.setItem(this.CURRENT_DOCUMENT, JSON.stringify(val));
  }
  static getCurrentDocument() {
    return localStore.getItem(this.CURRENT_DOCUMENT);
  }

  static clearCurrentDocument() {
    return localStore.removeItem(this.CURRENT_DOCUMENT);
  }

  static setErrorDocument(val) {
    localStore.setItem(this.ERROR_DOCUMENT, JSON.stringify(val));
  }
  static getErrorDocument() {
    return localStore.getItem(this.ERROR_DOCUMENT);
  }

  static clearErrorDocument() {
    return localStore.removeItem(this.ERROR_DOCUMENT);
  }
}
