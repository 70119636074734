import { StorageHelper } from "app/core/storageHelper";
import { errorHandler } from "app/core/errorHandler";
import axios from "axios";

export const API = axios.create({
  baseURL:
    StorageHelper.getAppSettings() && StorageHelper.getAppSettings().baseUrl,
});

API.interceptors.request.use((request) => {
  request.headers.Authorization = "Bearer " + StorageHelper.getJWTToken();
  return request;
});

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    errorHandler(error);
    return error.response;
    //return Promise.reject(error);
  }
);
