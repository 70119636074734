import { errorHandler } from 'app/core/errorHandler';
import axios from 'axios';
import { StorageHelper } from 'app/core/storageHelper';

export const API = axios.create({
  baseURL: StorageHelper.getAppSettings() && StorageHelper.getAppSettings().baseTemplateUrl
});

API.interceptors.request.use(
  request => {
    request.headers.Authorization = 'Bearer ' + StorageHelper.getJWTToken();
    return request;
  }
);

API.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (401 !== error.response.status) {
      return error.response;
    } else {
      errorHandler(error);
    }

  }
);
