import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import { storageFactory } from "app/core/storageFactory";
import metadata from 'metadata.json';


const Dashboard = lazy(() => import("app/secure/pages/Dashboard/Dashboard"));
const ErrorPage = lazy(() => import("app/secure/pages/ErrorPage/ErrorPage"));

const LoginRedirect = lazy(() =>
  import("app/secure/pages/LoginRedirect/LoginRedirect")
);

const localStore = storageFactory(() => sessionStorage);
const tokenInfo = JSON.parse(localStore.getItem("token_info"));
const userTYPE = (tokenInfo && tokenInfo.usertype) || "user";

export const routes = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to={`/${userTYPE}`}/>,
  },
  {
    path: "/oq",
    component: () => <h3>Hey There!!! The App is Healthy</h3>,
    exact: true,
  },
  {
    path: "/user",
    component: Dashboard,
    private: true,
  },
  {
    path: "/admin",
    component: Dashboard,
    private: true,
  },
  {
    path: "/archive",
    component: Dashboard,
    private: true,
  },
  {
    path: "/status",
    component: ErrorPage,
    private: true,
  },
  {
    path: "/login",
    component: LoginRedirect,
    private: true,
  },
];
