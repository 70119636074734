import * as actionTypes from "app/store/actions/actionTypes";
import { updateObject } from "app/store/storeUtils";

const initialState = {
  authToken: {},
  username: "",
  userid: "",
  usertype: "",
  lang: "en",
  userStudyList: [],
  selectedStudyID: "",
  appSettings: {},
  userRoleList: [],
};

export const authReducer = (state = initialState, action = { type: "" }) => {
  switch (action.type) {
    case `${actionTypes.FETCH_ROLE_LIST_VIEW}`:
      return updateObject(state, {});
    case `${actionTypes.FETCH_ROLE_LIST_VIEW}_SUCCESS`:
      const { userViews } = action.payload;
      return updateObject(state, { userRoleList: [...userViews] });
    case `${actionTypes.FETCH_ROLE_LIST_VIEW}_FAILED`:
      return updateObject(state, {});
    case actionTypes.SELECTED_STUDY_ID:
      const selectedStudyID = action.payload;
      return updateObject(state, { selectedStudyID });
    case actionTypes.SET_APP_SETTINGS:
      return updateObject(state, { appSettings: action.payload });
    case `${actionTypes.STUDY_INFO_DB_CHECK}`:
      return updateObject(state, { isloaded: false });
    case `${actionTypes.STUDY_INFO_DB_CHECK}_SUCCESS`:
      return updateObject(state, { isloaded: true });
    case `${actionTypes.STUDY_INFO_DB_CHECK}_FAILED`:
      return updateObject(state, { isloaded: true });
    default:
      return state;
  }
};
