import { API } from "./fileuploadbaseService";
import { StorageHelper } from "app/core/storageHelper";

export class FileuploadService {
  static doPassDocdetails = (data) => {
    const artifactPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/document/documentsave`;
    return API.post(artifactPath, data);
    //return API.get(FileuploadService.artifactPath, {headers: {'Authorization': 'Bearer '+FileuploadService.jwttoken}});
  };

  static dodownloadFileUrl = (data) => {
    const downloadPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/document/DownloadFile`;
    return API.post(downloadPath, data);
  };

  static doGetAzureStorage = () => {
    const appSettings = StorageHelper.getAppSettings();
    const storageAccountPath = `${appSettings.baseTemplateUrl}/api/v1/template/storageaccount`;
    return API.get(`${storageAccountPath}`);
  };

  static doScanningStatus = (fileGuid) => {
    const appSettings = StorageHelper.getAppSettings();
    const scanStatusPath = `${appSettings.baseDocumentUrl}/api/v1/documentscreening/status`;
    return API.get(`${scanStatusPath}/${fileGuid}`);
  };

  static doCreateNewDocVersion = (data) => {
    const appSettings = StorageHelper.getAppSettings();
    return API.post(
      `${appSettings.baseDocumentUrl}/api/v1/documentscreening/createnewversion`,
      data
    );
  };

  static doCancelScanning = (fileGuid) => {
    const appSettings = StorageHelper.getAppSettings();
    const cancelStatusPath = `${appSettings.baseDocumentUrl}/api/v1/documentscreening/cancel`;
    return API.put(`${cancelStatusPath}/${fileGuid}`);
  };
  static dodownloadDropzoneFileUrl = (data) => {
    const downloadPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/dropzone/download`;
    return API.post(downloadPath, data);
  };
  static doPreviewFile = (data) => {
    const previewPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/document/previewfile`;
    return API.post(previewPath, data);
  };
  static newVersionCancel = (data) => {
    const cancelPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/document/documentclonecancel`;
    return API.post(cancelPath, data);
  };
}
