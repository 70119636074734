import * as actionTypes from "app/store/actions/actionTypes";
import { updateObject } from "app/store/storeUtils";
import _ from "underscore";

const initialState = {
  loading: false,
  error: {},
};

export const loaderReducer = (state = initialState, action = { type: "" }) => {
  switch (action.type) {
    case actionTypes.SHOW_LOADER:
      return updateObject(state, { loading: true });
    case actionTypes.HIDE_LOADER:
      return updateObject(state, { loading: false });
    case actionTypes.SHOW_ERROR:
      const { error, downloadable, isSuccess } = action.payload;
      let updateError = {};
      if (!_.isEmpty(error))
        updateError = { errorTxt: error, downloadable, isSuccess };
      return updateObject(state, { error: updateError });
    case actionTypes.HIDE_ERROR:
      return updateObject(state, { error: {} });
    default:
      return state;
  }
};
