export const INCREMENT = "INCREMENT";
export const DECREMENT = "DECREMENT";
export const INCREMENT_ASYNC = "INCREMENT_ASYNC";
export const INCREMENT_IF_ODD = "INCREMENT_IF_ODD";
export const SET_COUNT_VALUE = "SET_COUNT_VALUE";

//Zone and Airtifacts
export const FETCH_ZONE_DATA = "FETCH_ZONE_DATA";
export const SET_ARTIFACT_ITEMS = "SET_ARTIFACT_ITEMS";
export const RESET_SELECTION = "RESET_SELECTION";
export const UPDATE_SELECTION = "UPDATE_SELECTION";
export const FETCH_METRICS_DATA = "FETCH_METRICS_DATA";
export const ADD_NEW_ZONE = "ADD_NEW_ZONE";
export const EDIT_ZONE = "EDIT_ZONE";
export const EDIT_SECTION = "EDIT_SECTION";
export const DELETE = "DELETE";

//Template
export const TEMPLATE_IMPORT = "TEMPLATE_IMPORT";
export const TEMPLATE_IMPORT_FAILED = "TEMPLATE_IMPORT_FAILED";
export const TEMPLATE_IMPORT_SUCCESS = "TEMPLATE_IMPORT_SUCCESS";

//Configurations
export const FETCH_CONFIGURATIONS = "FETCH_CONFIGURATIONS";
export const FETCH_CONFIGURATIONS_FAILED = "FETCH_CONFIGURATIONS_FAILED";
export const FETCH_CONFIGURATIONS_SUCCESS = "FETCH_CONFIGURATIONS_SUCCESS";

//Getting templates
export const FETCH_TEMPLATE = "FETCH_TEMPLATE";
export const FETCH_TEMPLATE_FAILED = "FETCH_TEMPLATE_FAILED";
export const FETCH_TEMPLATE_SUCCESS = "FETCH_TEMPLATE_SUCCESS";

//Loader
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const SHOW_ERROR = "SHOW_ERROR";
export const HIDE_ERROR = "HIDE_ERROR";

//Dialog
export const SHOW_PUBLISH_DIALOG = "SHOW_PUBLISH_DIALOG";
export const SHOW_VIEW_DOC_DIALOG = "SHOW_VIEW_DOC_DIALOG";
export const UPDATE_ERROR_DIALOG = "UPDATE_ERROR_DIALOG";

//Azure file upload
export const FILE_UPLOAD = "FILE_UPLOAD";
export const FILE_UPLOAD_FAILED = "FILE_UPLOAD_FAILED";
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const FILE_UPLOAD_PROGRESS = "FILE_UPLOAD_PROGRESS";

//Azure file download
export const FILE_DOWNLOAD_URL = "FILE_DOWNLOAD_URL";
export const FILE_DOWNLOAD_URL_FAILED = "FILE_DOWNLOAD_URL_FAILED";
export const FILE_DOWNLOAD_URL_SUCCESS = "FILE_DOWNLOAD_URL_SUCCESS";
export const IS_DOWNLOADING = "IS_DOWNLOADING";

//Document
export const FETCH_DOCUMENT_MASTER = "FETCH_DOCUMENT_MASTER";

export const DOCUMENT_STATUS_CHANGE = "DOCUMENT_STATUS_CHANGE";
export const DOCUMENT_STATUS_CHANGE_FAILED = "DOCUMENT_STATUS_CHANGE_FAILED";
export const DOCUMENT_STATUS_CHANGE_SUCCESS = "DOCUMENT_STATUS_CHANGE_SUCCESS";
export const IS_DOCUMENT_MODIFIED = "IS_DOCUMENT_MODIFIED";

export const ACTIVE_ADMIN_TAB = "ACTIVE_ADMIN_TAB";

// TMF Structure Data
export const TMF_STRUCTURE_DATA = "TMF_STRUCTURE_DATA";
export const ADD_REVIEW_COMMENT = "ADD_REVIEW_COMMENT";
export const EMPTY_REVIEW_COMMENT = "EMPTY_REVIEW_COMMENT";

export const FETCH_ALL_TEMPLATES = "FETCH_ALL_TEMPLATES";
export const SELECT_TEMPLATE = "SELECT_TEMPLATE";
export const EXPORT_TEMPLATE = "EXPORT_TEMPLATE";
export const DELETE_TEMPLATE = "DELETE_TEMPLATE";
export const LOGGEDIN_USER = "LOGGEDIN_USER";
export const STUDY_INFO = "STUDY_INFO";
export const RENAME_TEMPLATE = "RENAME_TEMPLATE";

export const GET_COUNTRY_DROPDOWN_LIST = "GET_COUNTRY_DROPDOWN_LIST";
export const GET_COUNTRY_SITE_LIST = "GET_COUNTRY_SITE_LIST";

export const FETCH_ROLE_LIST_VIEW = "FETCH_ROLE_LIST_VIEW";
export const SELECTED_STUDY_ID = "SELECTED_STUDY_ID";

export const SELECTED_FILTER_ID = "SELECTED_FILTER_ID";
export const SET_APP_SETTINGS = "SET_APP_SETTINGS";
export const INSTANTIATE_TEMPLATE = "INSTANTIATE_TEMPLATE";

//View Dialog
export const VIEW_DIALOG = "VIEW_DIALOG";
export const GET_VIEW_ZONE_SEC_ARTIF = "GET_VIEW_ZONE_SEC_ARTIF";

export const GET_DOCUMENY_BY_SECTION = "GET_DOCUMENY_BY_SECTION";
export const GET_DOCUMENY_BY_ARTIFACT = "GET_DOCUMENY_BY_ARTIFACT";

export const FETCH_DOCUMENT_DELETE = "FETCH_DOCUMENT_DELETE";
export const FETCH_ALL_CLIENT_APIS = "FETCH_ALL_CLIENT_APIS";
export const GET_APP_INIT_CONFIG = "GET_APP_INIT_CONFIG";
export const GET_ROLE_LIST = "GET_ROLE_LIST";
export const GET_STUDY_INFO = "GET_STUDY_INFO";
export const GET_USER_INFO = "GET_USER_INFO";
export const FETCH_SEARCH_RESULT = "FETCH_SEARCH_RESULT";
export const GO_TO_BROWSER_VIEW = "GO_TO_BROWSER_VIEW";

export const GUID_LIST = "GUID_LIST";
export const SCANNING_PROCESS_STATUS = "SCANNING_PROCESS_STATUS";
export const CREATE_NEW_DOC_VERSION = "CREATE_NEW_DOC_VERSION";
export const GET_USER_LIST = "GET_USER_LIST";
export const GET_ORG_ID = "GET_ORG_ID";

//eTMF ARCHIVE
export const GET_ETMF_ARCHIVING = "GET_ETMF_ARCHIVING";
export const EXPORT_ETMF_DOCUMENTS = "EXPORT_ETMF_DOCUMENTS";
export const DOWNLOAD_ETMF_DOCUMENT = "DOWNLOAD_ETMF_DOCUMENT";

export const SET_ARTIFACT_PAGINATION_INFO = "SET_ARTIFACT_PAGINATION_INFO";
export const MAINTENANCE_ON_OFF = "MAINTENANCE_ON_OFF";
export const MAINTENANCE_STATUS = "MAINTENANCE_STATUS";
export const SET_SEARCH_VIEW_PER_PAGE = "SET_SEARCH_VIEW_PER_PAGE";
export const SET_SEARCH_PAGE_INFO = "SET_SEARCH_PAGE_INFO";

export const ADD_NEW_ARTIFACT = "ADD_NEW_ARTIFACT";
export const GET_MILESTONE_LIST = "GET_MILESTONE_LIST";
export const GET_DATING_CONVENTION_LIST = "GET_DATING_CONVENTION_LIST";
export const FETCH_DOCUMENT_LOCK = "FETCH_DOCUMENT_LOCK";
export const GET_TMF_STRUCTURE_CHANGES = "GET_TMF_STRUCTURE_CHANGES";
export const REVERT_STRUCTURE_CHANGES = "REVERT_STRUCTURE_CHANGES";
export const APPLY_STRUCTURE_CHANGES = "APPLY_STRUCTURE_CHANGES";

export const GET_ENABLING_MILESTONE_STATUS = "GET_ENABLING_MILESTONE_STATUS";
export const UPDATING_ENABLING_MILESTONE_STATUS =
  "UPDATING_ENABLING_MILESTONE_STATUS";
export const TEMPLATE_FILE_SCREENING_STATUS = "TEMPLATE_FILE_SCREENING_STATUS";
export const GET_MILESTONE_INFO_LIST = "GET_MILESTONE_INFO_LIST";
export const CLOSE_VIEW_MILESTONE = "CLOSE_VIEW_MILESTONE";
export const GET_MILESTONE = "GET_MILESTONE";
export const GET_MILESTONE_COUNT = "GET_MILESTONE_COUNT";
export const GET_MILESTONE_ARTIFACT_COUNT = "GET_MILESTONE_ARTIFACT_COUNT";
export const CLOSE_SINGLE_MILESTONE_VIEW = "CLOSE_SINGLE_MILESTONE_VIEW";
export const ENABLE_FORM_ADD_MILESTONE = "ENABLE_FORM_ADD_MILESTONE";
export const ADD_NEW_MILESTONE = "ADD_NEW_MILESTONE";
export const EDIT_MILESTONE = "EDIT_MILESTONE";
export const GET_MILESTONE_GROUP = "GET_MILESTONE_GROUP";
export const GET_MILESTONE_FILTER_LIST = "GET_MILESTONE_FILTER_LIST";
export const CHANGE_MILESTONE_SEQUENCE = "CHANGE_MILESTONE_SEQUENCE";
export const DELETE_MILESTONE = "DELETE_MILESTONE";

export const ROLE_LIST_VIEW = "ROLE_LIST_VIEW";
export const GENERATE_COMPLETE_AUDIT_TRAIL = "GENERATE_COMPLETE_AUDIT_TRAIL";
export const GET_COMPLETE_AUDIT_TRAIL = "GET_COMPLETE_AUDIT_TRAIL";
export const DOWNLOAD_COMPLETE_AUDIT_TRAIL = "DOWNLOAD_COMPLETE_AUDIT_TRAIL";
export const STUDY_INFO_DB_CHECK = "STUDY_INFO_DB_CHECK";
export const SINGLE_FILE_PROGRESSING = "SINGLE_FILE_PROGRESSING";

export const RESET_DOCUMENT_DATA_LIST = "RESET_DOCUMENT_DATA_LIST";

export const GET_DROP_ZONE_SETTING = "GET_DROP_ZONE_SETTING";
export const GET_DROP_ZONE_DATA = "GET_DROP_ZONE_DATA";
export const GET_DROP_ZONE_FILESCREENING_STATUS = "GET_DROP_ZONE_FILESCREENING_STATUS";
export const DO_SAVE_TMF_DROP_ZONE = "DO_SAVE_TMF_DROP_ZONE";
export const DELETE_DROPZONE = "DELETE_DROPZONE";
export const DROP_ZONE_FILE_UPLOAD = "DROP_ZONE_FILE_UPLOAD";
export const DO_DROP_ZONE_FILESCREENING_STATUS = "DO_DROP_ZONE_FILESCREENING_STATUS";
export const RESET_PROGRESS_FILE_DATA = "RESET_PROGRESS_FILE_DATA";
export const SET_INVALID_FILE_ERROR = "SET_INVALID_FILE_ERROR";
export const REMOVE_INVALID_DOC = "REMOVE_INVALID_DOC";
export const REMOVE_DELETE_DOC = "REMOVE_DELETE_DOC";
export const REMOVE_SEARCH_DELETE_DOC = "REMOVE_SEARCH_DELETE_DOC";
export const ACTIVE_DROP_ZONE = "ACTIVE_DROP_ZONE";
export const SET_DROP_ITEMS = "SET_DROP_ITEMS";
export const GET_LOCK_STATUS = "GET_LOCK_STATUS";
export const SET_ETMF_LOCK = "SET_ETMF_LOCK";
export const SET_ETMF_UNLOCK = "SET_ETMF_UNLOCK";
export const GET_DOCU_TEMPLATE_NAMES = "GET_DOCU_TEMPLATE_NAMES";
export const UPDATE_TEMPLATE_NAMES = "UPDATE_TEMPLATE_NAMES";
export const FILE_PREVIEW_URL = "FILE_PREVIEW_URL";
export const GET_AGGRID_DATA = "GET_AGGRID_DATA";
export const FILE_PREVIEW_OPEN = "FILE_PREVIEW_OPEN";
export const FILE_PREVIEW_URL_AUDIT = "FILE_PREVIEW_URL_AUDIT";
export const GET_SHARED_DOCUMENT = "GET_SHARED_DOCUMENT";
export const FETCH_ALL_AGGRID_DATA = "FETCH_ALL_AGGRID_DATA";
export const GET_ALL_DATA_CLICKED = "GET_ALL_DATA_CLICKED";
export const GET_AGGRID_RESULT_COUNT = "GET_AGGRID_RESULT_COUNT";
export const SAVE_CUSTOMIZED_COLSTATE = "SAVE_CUSTOMIZED_COLSTATE";
export const GET_CUSTOMIZED_COLSTATE = "GET_CUSTOMIZED_COLSTATE";
export const SET_AGGRID_FROM = "SET_AGGRID_FROM";
export const ENABLE_AGGRID_ICON = "ENABLE_AGGRID_ICON";
export const SAVE_ALL_AG_DATA = "SAVE_ALL_AG_DATA";
export const SET_LAST_MODE = "SET_LAST_MODE";
export const SET_CURRENT_MODE = "SET_CURRENT_MODE";
export const IS_SEARCH_HAPPEND = "IS_SEARCH_HAPPEND";
export const IS_CLOSE_HAPPEND = "IS_CLOSE_HAPPEND";
export const MAKE_SEARCH_VIEW = "MAKE_SEARCH_VIEW";
export const IS_CANCEL_TEMPLATE = "IS_CANCEL_TEMPLATE";
export const REMOVE_INVALID_DOCUMENT = "REMOVE_INVALID_DOCUMENT";
export const GET_TIME_FORMAT = "GET_TIME_FORMAT";
export const CLONE_DOC_NEW_VERSION = "CLONE_DOC_NEW_VERSION";
export const SET_ACTIVE_DOCID = "SET_ACTIVE_DOCID";
export const CLOSE_DOC_NEW_VERSION = "CLOSE_DOC_NEW_VERSION";
export const SET_VIEW_DIALOG_DETAILS = "SET_VIEW_DIALOG_DETAILS";
export const GET_DOCUMENT_VERSION_LIST = "GET_DOCUMENT_VERSION_LIST";
export const GET_SELECTED_DOCUMENT_DETAIL = "GET_SELECTED_DOCUMENT_DETAIL";
export const COMING_FROM_PAGE = "COMING_FROM_PAGE";
export const CANCEL_NEWVERSION_AFTER_CREATE = "CANCEL_NEWVERSION_AFTER_CREATE";
export const RESET_VIEW_DIALOG = "RESET_VIEW_DIALOG";
export const ADD_DOC_NOTE = "ADD_DOC_NOTE";
export const CLEAR_DOC_NOTE = "CLEAR_DOC_NOTE";
export const GET_DOC_NOTE = "GET_DOC_NOTE";
export const RESOLVE_DOC_NOTE = "RESOLVE_DOC_NOTE";
export const PRE_VERSION_ID = "PRE_VERSION_ID";
export const UPLOADED_NEW_VERSION_FILE = "UPLOADED_NEW_VERSION_FILE";
export const GET_SHARED_FILE_COUNT = "GET_SHARED_FILE_COUNT";
export const GET_USER_LIST_SHARE = "GET_USER_LIST_SHARE";
export const UPDATE_SHARED_FILE_MEMEBERS = "UPDATE_SHARED_FILE_MEMEBERS";
export const OPEN_USER_LIST_MODEL = "OPEN_USER_LIST_MODEL";
export const UPDATE_USER_SHARED_LIST = "UPDATE_USER_SHARED_LIST";
export const GET_DOC_PROP_HISTROY = "GET_DOC_PROP_HISTROY";
export const GET_SHARING_METRICS_BY_VERSION = "GET_SHARING_METRICS_BY_VERSION";
export const GET_DOC_SHARE_USER_LIST = "GET_DOC_SHARE_USER_LIST";
export const CREATE_NEW_BINDER_FOR_SHARING = "CREATE_NEW_BINDER_FOR_SHARING";
export const GET_EXISTING_SHARED_USER_LIST = "GET_EXISTING_SHARED_USER_LIST";
export const GET_BINDERS_DETAIL = "GET_BINDERS_DETAIL";
export const CLEAR_BINDER_METRICS = "CLEAR_BINDER_METRICS";
export const IS_BINDERS_CALL_RUNNING = "IS_BINDERS_CALL_RUNNING";
export const SHOW_DOWNLOAD_LOADER = "SHOW_DOWNLOAD_LOADER";
export const GET_DOCUMENT_CONTENT_DETAILS = "GET_DOCUMENT_CONTENT_DETAILS";
export const RECALL_BINDER = "RECALL_BINDER";
export const GET_BINDERS_HISTORY = "GET_BINDERS_HISTORY";
export const GET_ADMIN_SHARING_SETTING = "GET_ADMIN_SHARING_SETTING";
export const DOWNLOAD_SIGNATURE_DETAILS = "DOWNLOAD_SIGNATURE_DETAILS";
export const ERROR_WITH_DATE_VALUE = "ERROR_WITH_DATE_VALUE";
export const TO_PASS_MODIFIED_FILE_DATA = "TO_PASS_MODIFIED_FILE_DATA";
export const IS_EXPAND_WINDOW = "IS_EXPAND_WINDOW";
export const HIDE_MODAL = "HIDE_MODAL";
export const GET_HISTORY_DETAILS = "GET_HISTORY_DETAILS";
export const DOWNLOAD_SHARING_DETAILS = "DOWNLOAD_SHARING_DETAILS";
export const GET_DOWNLOAD_SHARING_STATUS = "GET_DOWNLOAD_SHARING_STATUS";
export const DOWNLOAD_SIGNATURE_ZIP_DETAILS = "DOWNLOAD_SIGNATURE_ZIP_DETAILS";
export const DOWNLOAD_WITH_NOTES = "DOWNLOAD_WITH_NOTES";