import { zoneReducer } from "./zoneReducer";
import { templateReducer } from "./templateReducer";
import { authReducer } from "./authReducer";
import { loaderReducer } from "./loaderReducer";
import { dialogReducer } from "./dialogReducer";
import { fileuploadReducer } from "./fileuploadReducer";
import { documentReducer } from "./documentReducer";
import { combineReducers } from "redux";

export const rootReducer = combineReducers({
    zone: zoneReducer,
    dialog: dialogReducer,
    template: templateReducer,
    auth: authReducer,
    loader: loaderReducer,
    file: fileuploadReducer,
    document: documentReducer,
});