import { API } from "./importBaseService";
import { StorageHelper } from "app/core/storageHelper";

export class TemplateService {
  static doImportOrgTemplate = (data) => {
    const contextPath = `${StorageHelper.getAppSettings().baseTemplateUrl
      }/api/v1/template/org/import`;
    return API.post(contextPath, data);
  };

  static doImportStudyTemplate = (data) => {
    const contextPath = `${StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/template/study/import`;
    return API.post(contextPath, data);
  };

  static doImportViedocTemplate = (data) => {
    const contextPath = `${StorageHelper.getAppSettings().baseTemplateUrl
      }/api/v1/template/system/import`;
    return API.post(contextPath, data);
  };

  static doFetchTemplate = () => {
    const contextPath = `${StorageHelper.getAppSettings().baseTemplateUrl
      }/api/v1/template`;
    return API.get(contextPath);
  };

  static doFetchConfiguration = () => {
    const configPath = `${StorageHelper.getAppSettings().baseTemplateUrl
      }/configuration`;
    return API.get(configPath);
  };

  static doFetchOrgAndViedocTemplates = (orgGuid) => {
    const contextPath = `${StorageHelper.getAppSettings().baseTemplateUrl
      }/api/v1/template`;
    return API.get(`${contextPath}?orgId=${orgGuid}`);
  };

  static doFetchStudyTemplates = () => {
    const contextPath = StorageHelper.getAppSettings().baseDocumentUrl;
    return API.get(`${contextPath}/api/v1/template/study`);
  };

  static doFetchLoggedInUser = () => {
    const contextPath = `${StorageHelper.getAppSettings().baseTemplateUrl
      }/api/v1/template`;
    return API.get(`${contextPath}/studyinformation`);
  };

  static doFetchStudyInfo = () => {
    const contextPath = `${StorageHelper.getAppSettings().baseTemplateUrl
      }/api/v1/template`;
    return API.get(`${contextPath}/studyinformation`);
  };

  static doSelectTemplate = (data) => {
    const contextPath = `${StorageHelper.getAppSettings().baseTemplateUrl
      }/api/v1/template/select`;
    return API.post(`${contextPath}`, data);
  };

  static doSelectStudyTemplate = (data) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextDocPath = `${appSettings.baseDocumentUrl}/api/v1/template/study/select`;
    return API.post(`${contextDocPath}`, data);
  };

  static doInstantiateTemplate = (data) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextDocPath = `${appSettings.baseDocumentUrl}/api/v1/document`;
    return API.put(`${contextDocPath}/instantiatetemplate`, data);
  };

  static doFetchZoneSectionArtifact = (data) => {
    const { from, id } = data;
    const contextPath = `${StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1`;
    return API.get(`${contextPath}/${from}/${id}`);
  };

  static doExportOrgAndViedocTemplates = (data) => {
    const contextPath = `${StorageHelper.getAppSettings().baseTemplateUrl
      }/template/export`;
    return API.post(contextPath, data, { responseType: "arraybuffer" });
  };

  static doExportStudyTemplate = (data) => {
    const contextPath = `${StorageHelper.getAppSettings().baseDocumentUrl
      }/template/study/export`;
    return API.post(contextPath, data, { responseType: "arraybuffer" });
  };

  static doExportTmfTemplate = (data) => {
    const contextPath = `${StorageHelper.getAppSettings().baseDocumentUrl
      }/template/tmfstructure/export`;
    return API.post(contextPath, data, { responseType: "arraybuffer" });
  };

  static doDeleteOrgAndViedocTemplate = (templateId) => {
    const appSettings = StorageHelper.getAppSettings();
    return API.delete(
      `${appSettings.baseTemplateUrl}/api/v1/template/delete?templateId=${templateId}`
    );
  };

  static doDeleteStudyTemplate = (templateId) => {
    const appSettings = StorageHelper.getAppSettings();
    return API.delete(
      `${appSettings.baseDocumentUrl}/api/v1/template/study/delete?templateId=${templateId}`
    );
  };

  static doGetEtmfArchiving = () => {
    const contextPath = `${StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/archive/ArchiveDetail`;
    return API.get(`${contextPath}`);
  };

  static doStartExportDocuments = (data) => {
    const contextPath = `${StorageHelper.getAppSettings().baseFunctionUrl
      }/api/ExportArchive?`;
    return API.post(contextPath, data);
  };

  static doCancelExportDocuments = (data) => {
    const contextPath = `${StorageHelper.getAppSettings().baseFunctionUrl
      }/api/ArchiveCancel?`;
    return API.post(contextPath, data);
  };

  static doWatchExportDocuments = (data) => {
    const contextPath = `${StorageHelper.getAppSettings().baseFunctionUrl
      }/api/ArchiveStatus?`;
    return API.post(contextPath, data);
  };

  static dodownloadExportFileUrl = (data) => {
    const downloadPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseTemplateUrl
      }/api/v1/template/ArchiveFileDownload?zipname=${data.downloadFileName}.${data.type
      }`;
    return API.get(downloadPath);
  };

  static doMaintenanceOnOff = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/templatemaintenance/${data}`;
    return API.put(contextPath, {});
  };

  static getEnablingMilestoneStatus = () => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/study/settings`;
    return API.get(contextPath);
  };

  static doEnablingMilestoneStatus = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/study/settings`;
    return API.put(contextPath, data);
  };

  static getMaintenanceStatus = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/templatemaintenance/status`;
    return API.get(contextPath);
  };

  static doAddNewZone = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/zone`;
    return API.post(contextPath, data);
  };

  static editZone = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/zone/edit`;
    return API.post(contextPath, data);
  };

  static editSection = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/section/edit`;
    return API.post(contextPath, data);
  };
  static doGetTimeFormat = () => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseTemplateUrl
      }/api/v1/studyinfo/admin`;
    return API.get(contextPath);
  };
  static doRenameOrgTemplate = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseTemplateUrl
      }/api/v1/template/org/rename`;
    return API.put(contextPath, data);
  };

  static doRenameStudyTemplate = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/template/study/rename`;
    return API.put(contextPath, data);
  };

  static doRenameSelectedTemplate = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/template/rename`;
    return API.put(contextPath, data);
  };

  static doFetchSAStoken = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/document/sasgeneration`;
    return API.get(contextPath);
  };
  static doAddNewArtifact = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/artifact/addoredit`;
    return API.post(contextPath, data);
  };

  static doFetchMileStoneList = () => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/artifact/milestones`;
    return API.get(contextPath);
  };
  static doFetchDatingConventionList = () => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/artifact/Datingconvention`;
    return API.get(contextPath);
  };
  static deleteStruture = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/tmfstructure/delete`;
    return API.put(contextPath, data);
  };

  static doTMFStructureChanges = () => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/templatemaintenance/changes`;
    return API.get(contextPath);
  };

  static doRevertStructureChange = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/tmfstructure/revert`;
    return API.put(contextPath, data);
  };

  static doApplyStructureChange = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/templatemaintenance/applychanges`;
    return API.put(contextPath, data);
  };

  static doFileScreeningStatus = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/filescreeningstatus`;
    return API.get(`${contextPath}/${data}`);
  };

  static doFileScreeningCancel = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/filescreeningstatus/cancel`;
    return API.put(contextPath, data);
  };

  static doFetchMileStoneFilterList = () => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/milestones/filter`;
    return API.get(contextPath);
  };

  static doFetchAuditTrailDetail = () => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/archivefile/detail`;
    return API.get(contextPath);
  };

  static doCompleteAuditTrail = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/archivefile`;
    return API.post(contextPath, data);
  };

  static doFetchStatusAuditTrail = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/archivefile/status`;
    return API.get(`${contextPath}?id=${data}`);
  };

  static doCancelAuditTrail = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/archivefile/cancel`;
    return API.put(contextPath, data);
  };

  static doDownloadAuditTrail = (data) => {
    const contextPath = `${StorageHelper.getAppSettings().baseTemplateUrl
      }/api/v1/template/archivefile/download`;
    return API.post(contextPath, data);
  };
  static getDropZoneSettings = () => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/dropzone/filecount`;
    return API.get(contextPath);
  };
  static doDropZoneFileScreeningCancel = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/dropzone/cancel`;
    return API.put(contextPath, data);
  };
  static getDropZoneDocuments = (category) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.get(`${contextPath}dropzone/${category}`);
  };
  static doDropZoneFileScreening = (payload) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/dropzone/screeningstatus`;
    return API.post(contextPath, payload);
  };
  static getDropZoneFileScreening = (payload) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/dropzone/screeningstatus/`;
    return API.get(`${contextPath}/${payload}`);
  };
  static doSaveTMFDropZone = (payload) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/dropzone`;
    return API.post(contextPath, payload);
  };
  static deleteDropZoneDocument = (payload) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/dropzone/${payload}`;
    return API.delete(contextPath);
  };
  static getEtmfLockStatus = () => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/etmf/lockstatus`;
    return API.get(contextPath);
  };
  static setetmflock = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseTemplateUrl
      }/api/v1/etmflock/lock`;
    return API.put(contextPath, data);
  };
  static setetmfunlock = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseTemplateUrl
      }/api/v1/etmflock/unlock`;
    return API.put(contextPath, data);
  };
  static getDocumentTemplateNames = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/documenttemplate/variables`;
    return API.get(contextPath, data);
  };
  static updateTemplateNames = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/documenttemplate`;
    return API.put(contextPath, data);
  };
  static getAgGridData = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/aggridview/aggriddatalist`;
    return API.get(contextPath, data);
  };
  static getSharedDocument = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/documentsharing/tmf/` + data;
    return API.get(contextPath);
  };
  static saveCustomizedColState = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/userpreference/aggrid`;
    return API.put(contextPath, data);
  };
  static getCustomizedColState = (data) => {
    const contextPath = `${StorageHelper.getAppSettings() &&
      StorageHelper.getAppSettings().baseDocumentUrl
      }/api/v1/userpreference/aggrid`;
    return API.get(contextPath);
  };

  static getTmfStructureDetails = () => {
    const appSettings = StorageHelper.getAppSettings();
    return API.get(
      `${appSettings.baseDocumentUrl}/api/v1/Structure/gettmfStructuredetails`
    )
  };

  static saveTmfStructureDetails = (payload) => {
    const appSettings = StorageHelper.getAppSettings();
    return API.post(
      `${appSettings.baseDocumentUrl}/api/v1/Structure/edit`
      , payload)
  };
  static cloneDocNewVersion = (data) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.post(`${contextPath}document/documentclonesave`, data);
  };
}