import { all } from 'redux-saga/effects';

import { watchTemplateAsync } from "./templateSaga";
import { watchTokenAsync } from "./authSaga";
import { watchFileAsync } from "./fileupoadSaga";
import { watchDocumentAsync } from "./documentSaga";
import { watchZoneAsync } from "./zoneSaga";

export function* rootSaga() {
  yield all([
    watchTemplateAsync(),
    watchTokenAsync(),
    watchFileAsync(),
    watchDocumentAsync(),
    watchZoneAsync()
  ])
}