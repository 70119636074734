import * as actionTypes from "app/store/actions/actionTypes";
import { updateObject } from "app/store/storeUtils";
import _ from "underscore";

const initialState = {
  publishDialog: { open: false },
  viewDialog: { open: false },
  error: "",
};

export const dialogReducer = (state = initialState, action = { type: "" }) => {
  switch (action.type) {
    case actionTypes.SHOW_PUBLISH_DIALOG: {
      if (!action.error) {
        return updateObject(state, {
          publishDialog: {
            ...action.payload,
            open: !state.publishDialog.open,
          },
          error: "",
        });
      }
      return updateObject(state, {
        error: action.error,
      });
    }
    case actionTypes.SHOW_VIEW_DOC_DIALOG: {
      if (!action.error) {
        return updateObject(state, {
          viewDialog: {
            ...action.payload,
            open: !_.isEmpty(action.payload),
          },
          error: "",
        });
      }
      return updateObject(state, {
        error: action.error,
      });
    }
    case actionTypes.SET_VIEW_DIALOG_DETAILS: {
      if (!action.error) {
        return updateObject(state, {
          viewDialog: {
            ...action.payload,
            open: !_.isEmpty(action.payload),
          },
          error: "",
        });
      }
      return updateObject(state, {
        error: action.error,
      });
    }
    case actionTypes.UPDATE_ERROR_DIALOG: {
      return updateObject(state, {
        error: action.error,
      });
    }
    default:
      return state;
  }
};
