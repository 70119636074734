import { Intent, Position, Toaster } from '@blueprintjs/core';

class AlertHelper {

  static POSITION = Position.RIGHT_TOP;

  static AppToaster = Toaster.create({
    position: this.POSITION,
    intent: Intent.SUCCESS,
  });

  static showError = (msg) => {
    AlertHelper.AppToaster.show({
      intent: Intent.DANGER,
      message: msg
    })
  }

  static showSuccess = (msg) => {
    AlertHelper.AppToaster.show({
      intent: Intent.SUCCESS,
      message: msg
    });
  }
}

export default AlertHelper;
