import { API } from "./fileuploadbaseService";
import { StorageHelper } from "app/core/storageHelper";

export class ZoneService {
  static doGetZoneData = () => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/Zone/list`;
    return API.get(contextPath);
  };

  static doGetArtifactDocs = (id, origid) => {
    //let artifactid = parseInt(origid.split(".")[2]);
    // return API.get(`${ZoneService.docPath}?artifactid=${id}`, {headers: {'Authorization': 'Bearer '+ZoneService.jwttoken}});
    const appSettings = StorageHelper.getAppSettings();
    const docPath = `${appSettings.baseDocumentUrl}/api/v1/document`;
    return API.get(`${docPath}/${origid}`);
  };

  static doGetDocumentsBySection = (sectionID) => {
    const appSettings = StorageHelper.getAppSettings();
    const docListBySection = `${appSettings.baseDocumentUrl}/api/v1/document/zoneDetail`;
    return API.get(`${docListBySection}/${sectionID}`);
  };

  static doGetDocumentsByArtifact = (artifactID) => {
    const appSettings = StorageHelper.getAppSettings();
    const docListBySection = `${appSettings.baseDocumentUrl}/api/v1/document`;
    return API.get(`${docListBySection}/${artifactID}`);
  };

  static doGetCountryList = () => {
    const appSettings = StorageHelper.getAppSettings();
    return API.get(`${appSettings.baseDocumentUrl}/api/v1/country/list`);
  };

  static doGetSiteList = () => {
    const appSettings = StorageHelper.getAppSettings();
    return API.get(`${appSettings.baseDocumentUrl}/clinic/sites`);
  };

  static doGetAppInitConfig = () => {
    const appSettings = StorageHelper.getAppSettings();
    const docListBySection = `${appSettings.baseDocumentUrl}/api/v1/study/appinitconfig`;
    return API.get(docListBySection);
  };

  static doGetViedocInfo = () => {
    const appSettings = StorageHelper.getAppSettings();
    return API.get(`${appSettings.baseDocumentUrl}/api/v1/viedoc/info`);
  };

  static doGetSearchResult = (data) => {
    const appSettings = StorageHelper.getAppSettings();
    const docListBySection = `${appSettings.baseDocumentUrl}/api/v1/search/aggridgetdocument`;
    return API.post(docListBySection, data);
  };

  static doGetMetricsData = (payload) => {
    const appSettings = StorageHelper.getAppSettings();
    return API.get(
      `${appSettings.baseDocumentUrl}/api/v1/metrics?siteid=${payload.siteID}&countrycode=${payload.countryID}&milestoneGroup=${payload.milestoneGroup}&milestoneId=${payload.milestoneId}`
    );
  };

  static getRefreshToken = () => {
    const appSettings = StorageHelper.getAppSettings();
    return API.get(
      `${appSettings.baseTemplateUrl}/api/v1/token/refreshtoken`
    )
  };
}
