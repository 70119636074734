import * as actionTypes from "app/store/actions/actionTypes";

export const fetchRoleListView = (payload) => ({
  type: actionTypes.FETCH_ROLE_LIST_VIEW,
  payload,
});
export const fetchRoleListViewFailed = (payload) => ({
  type: `${actionTypes.FETCH_ROLE_LIST_VIEW}_FAILED`,
  payload,
});
export const fetchRoleListViewSuccess = (payload) => ({
  type: `${actionTypes.FETCH_ROLE_LIST_VIEW}_SUCCESS`,
  payload,
});

export const setSelectedStudyID = (payload) => ({
  type: actionTypes.SELECTED_STUDY_ID,
  payload,
});

export const fetchDBCheck = (payload) => ({
  type: actionTypes.STUDY_INFO_DB_CHECK,
  payload,
});
export const fetchDBCheckFailed = (payload) => ({
  type: `${actionTypes.STUDY_INFO_DB_CHECK}_FAILED`,
  payload,
});
export const fetchDBCheckSuccess = (payload) => ({
  type: `${actionTypes.STUDY_INFO_DB_CHECK}_SUCCESS`,
  payload,
});
