import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware, compose } from "redux";

import { rootReducer } from "app/store/reducers/rootReducer";
import { rootSaga } from "app/store/sagas/rootSaga";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware))

);

sagaMiddleware.run(rootSaga);
