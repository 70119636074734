import { put, takeEvery, delay } from "redux-saga/effects";
import * as actionTypes from "app/store/actions/actionTypes";
import * as actions from "app/store/actions/authActions";
import * as actionsLoader from "app/store/actions/loaderActions";
import { AuthService } from "app/secure/services/authService";
import { StorageHelper } from "app/core/storageHelper";
import { UtilsHelper } from "app/core/utilsHelper";
export function* fetchRoleListViewSaga() {
  try {
    const response = yield AuthService.doFetchRoleListView();
    const { isError, result } = response.data;
    if (!isError) {
      yield put(actions.fetchRoleListViewSuccess(result));
    } else yield put(actions.fetchRoleListViewFailed());
  } catch (error) {
    yield put(actions.fetchRoleListViewFailed());
  }
}

export function* fetchDBCheckSaga(action) {
  try {
    const response = yield AuthService.doFetchDBCheck();
    const { isError, result } = response.data;
    if (!isError) {
      yield put(actions.fetchDBCheckSuccess(result.status));
      if (!result.status) {
        yield delay(10000);
        yield put(actions.fetchDBCheck());
        action.payload.push({ pathname: "/status" });
      } else {
        yield put(actions.fetchRoleListView());
      }
    } else yield put(actions.fetchDBCheckFailed());
  } catch (error) {
    yield put(actions.fetchDBCheckFailed());
  }
}

export function* watchTokenAsync() {
  yield takeEvery(actionTypes.FETCH_ROLE_LIST_VIEW, fetchRoleListViewSaga);
  yield takeEvery(actionTypes.STUDY_INFO_DB_CHECK, fetchDBCheckSaga);
}
